// import Collapse from 'react-bootstrap/Collapse';
import React from "react";
import { useState ,useEffect} from "react";
// import { Chapter, Chart, Cloud,  Key, Mobile,  Teacher, Timer2 } from "../../../imagepath";

// import FeatherIcon from "feather-icons-react";
import CourseOverview from "./CourseOverview";
import CourseContent from "./CourseContent";
import LessonContent from "./LessonContent";
import {ShareCalculatorTool,Toolkit2,Toolkit3,Toolkit4,Toolkit5,Toolkit6,Program2Toolkit1,Program2Toolkit2,Program2Toolkit3,Program2Toolkit4,
        Program2Toolkit5,
        PMOSToolkit1,
        PMOSToolkit2,PMOSToolkit3,Program3Toolkit1,Program3Toolkit3,Program3Toolkit5,IVoTMoneyGameofLife} from "./tools";
import {createUserEvent,getToolkitData } from "../../../../api/programApi";
import PropTypes from 'prop-types';
import "./tools/tool.css";
import { useTranslation } from "react-i18next";
import {truncateString} from "../../../../utils/helper";
import { trackEvent } from "../../../../utils/trackEvent";



const DetailsContent = ({trainingProgram,percentCompletion ,fetchLessonStatuses}) => {
  const { t } = useTranslation();
    
    const [activeToolkit, setActiveToolkit] = useState("");
    const [activeLesson,setActiveLesson] = useState(null);
    const [toolkitData, setToolkitData] = useState({});    
    const [openedCourse,setOpenedCourse] = useState(null);
    const [activeTab, setActiveTab] = useState('overview');
    const [isLastLesson, setIsLastLesson] = useState(false);
     // eslint-disable-next-line no-unused-vars
    const [trainingProgramsPercent, setTrainingProgramsPercent] = useState(percentCompletion || 0);
    
    const language = localStorage.getItem('userLanguage');
    
    const tabs = [
      { id: 'overview',  name: t('overview') },
    ]

     // Function to navigate back to Program Content tab
      const handleBackToProgramContent = () => {
        setActiveTab('overview'); 
      };

      // Function to add lesson tab and set the active lesson
    const addLessonTab = (lesson,isLast,openCourse) => {
    
    setActiveLesson(lesson); 
    setActiveTab("lesson"); 
    setOpenedCourse(openCourse)
    setIsLastLesson(isLast);
  };

    
// Set the default active toolkit when lesson changes and toolkit array exists
useEffect(() => {
  if (activeLesson && activeLesson.toolkit && activeLesson.toolkit.length > 0) {
    setActiveToolkit(activeLesson.toolkit[0]);

    activeLesson.toolkit.forEach(async (toolkit) => {
      const data = await getToolkitData(toolkit, language);
      setToolkitData((prevData) => ({
        ...prevData,
        [toolkit]: data,
      }));
    });
  }
}, [activeLesson, language]);





  const handleTrainingProgramCompleted = async () => {
    const eventData = {
    eventType: "Training Program Completed",
    userEmail: localStorage.getItem("Email"),  
    trainingId: trainingProgram.srno,
    courseId: activeLesson.course_id[0],
    lessonId: activeLesson.srno,
    };

    try {
    const response = await createUserEvent(
      eventData.eventType,
      eventData.userEmail,
      eventData.trainingId,
      eventData.courseId,
      eventData.lessonId
    );
    console.log("Training Program Completed event tracked successfully", response);
    
    } catch (error) {
    console.error("Error tracking training Program Completed event", error);
    }
  };
  
    
    // const [open, setOpen] = useState(false);
    // const [open2, setOpen2] = useState(false);
    // const [open3] = useState(false);
    // const [open4] = useState(false);
    useEffect(() => {
      if (percentCompletion) {
        setTrainingProgramsPercent(percentCompletion);
      }
    }, [percentCompletion]);

    useEffect(() => {
      const userEmail = localStorage.getItem('Email');
      const trainingId = trainingProgram.srno; 

      
  
      if (userEmail && trainingId) {
        const eventType = 'Training Program Opened';
        
        
        createUserEvent(eventType, userEmail, trainingId)
          .then((response) => {
            console.log('User event Training Program Opened created successfully:', response);
          })
          .catch((error) => {
            console.error('Error creating user event:', error);
          });
      }
    }, []); 

    useEffect(() => {
      if (activeToolkit) {
        trackEvent('Toolkit', 'Visited', activeToolkit);
      }
    }, [activeToolkit]);

    useEffect(() => {
      if (trainingProgram?.program_header) {
        trackEvent('Training Program', 'Visited Training Program', trainingProgram.program_header);
      }
    }, [trainingProgram]);


  return (
    <>
      <section className="page-content course-sec bg-light-grayish-blue" >
        <div className="container">
        <ul className="nav nav-tabs " role="tablist" style={{paddingLeft:"22px"}}>
           {(trainingProgram?.number_of_lessons >= 1) &&   tabs.map((tab) => (
              <li key={tab.id} className="nav-item cursor-pointer" style={{marginRight:"10px"}}>
                <a
                  className={`nav-link overview-border  ${activeTab === tab.id ? 'active' : ''}`}
                  onClick={() => setActiveTab(tab.id)}
                  role="tab"
                  style={{marginBottom:"0px",paddingBottom:"6px"}}
                >
                  {tab.name} 
                </a>
              </li>
            ))}
             {activeTab !== "overview" && activeLesson && (<li className="nav-item cursor-pointer" key="lesson">
              <a className={`nav-link limit-label-text ${activeTab.startsWith('lesson') ? 'active' : ''}`}
                 onClick={() => setActiveTab("lesson")}
                 role="tab"
              >
                {truncateString(activeLesson.lesson_header)} 
                </a>
              
              </li>)}
    
        </ul>
           
          <div className="row">
            <div className={`${(activeToolkit === 'Toolkit3' || activeToolkit === 'Toolkit4' || activeToolkit === 'Program2Toolkit1' || activeToolkit === 'Program2Toolkit2' || activeToolkit === 'Program2Toolkit3' || activeToolkit === 'Program2Toolkit4' || activeToolkit === 'Program2Toolkit5' || activeToolkit === 'PMOSToolkit1' || activeToolkit === 'PMOSToolkit2' || activeToolkit === 'Program3Toolkit1' || activeToolkit === 'Program3Toolkit3' || activeToolkit === 'Program3Toolkit5' || activeToolkit === 'IVoTMoneyGameofLife') ? 'col-12 col-md-6 col-lg-6' : 'col-lg-7'}`}
            >
              {activeTab === 'overview' && <CourseOverview trainingProgram={trainingProgram}/>}
              {/* {activeTab === 'content' && <CourseContent trainingProgram={trainingProgram} onStartLesson={addLessonTab} fetchLessonStatuses={fetchLessonStatuses}/>}              */}
              {activeTab === "lesson" && <LessonContent lesson={activeLesson} trainingProgram={trainingProgram} isLastLesson={isLastLesson} onTrainingCompleted={handleTrainingProgramCompleted} onBackToProgramContent={handleBackToProgramContent} fetchLessonStatuses={fetchLessonStatuses} openedCourse={openedCourse} onStartLesson={addLessonTab} />}

            </div>

            <div className={`${(activeToolkit === 'Toolkit3' || activeToolkit === 'Toolkit4' || activeToolkit === 'Program2Toolkit1' || activeToolkit === 'Program2Toolkit2' || activeToolkit === 'Program2Toolkit3' || activeToolkit === 'Program2Toolkit4' || activeToolkit === 'Program2Toolkit5'  || activeToolkit === 'PMOSToolkit1' || activeToolkit === 'PMOSToolkit2' || activeToolkit === 'Program3Toolkit1' || activeToolkit === 'Program3Toolkit3' ||  activeToolkit === 'Program3Toolkit5' ||  activeToolkit === 'IVoTMoneyGameofLife') ? 'col-12 col-md-6 col-lg-6' : 'col-lg-5'}`}
            >
              <div className="sidebar-sec">
                {/* Video */}
                  {/* {trainingProgram.intro_video && (
                    <div className="video-sec vid-bg">
                      <div className="card">
                        <div className="card-body">
                          <div className="video-details">
                            <div className="course-fee">
                              <h2>{t("intro")}</h2>
                              <p>{trainingProgramsPercent} % {t("completed")}</p>
                            </div>
                          </div>
                          <object data={trainingProgram.intro_video}></object>
                        </div>
                      </div>
                    </div>
                  )} */}
                  {/* /Video */}

                {/* /Video */}


    

                {activeTab === "lesson"  && activeLesson.toolkit && activeLesson.toolkit.length > 0 && (
                <div className="toolkit-container">
                  {/* <div className="tool-tabs">
                  <span style={{float:"left",padding:"10px"}}>Toolkits:</span>
                      {activeLesson.toolkit.includes('Toolkit1') && (
                          <button
                          className={`btn btn-primary tool-tab ${activeToolkit === 'Toolkit1' ? 'active' : ''}`}
                          onClick={() => setActiveToolkit('Toolkit1')}
                        >
                          {t("Tool")} 1
                        </button>
                        )}
                  
                      {activeLesson.toolkit.includes('Toolkit2') && (
                          <button
                          className={`btn btn-primary  tool-tab ${activeToolkit === 'Toolkit2' ? 'active' : ''}`}
                          onClick={() => setActiveToolkit('Toolkit2')}
                        >
                          {t("Tool")} 2
                        </button>
                        )}
                      {activeLesson.toolkit.includes('Toolkit3') && (
                          <button
                          className={`btn btn-primary  tool-tab ${activeToolkit === 'Toolkit3' ? 'active' : ''}`}
                          onClick={() => setActiveToolkit('Toolkit3')}
                        >
                          {t("Tool")} 3
                        </button>
                        )}
                      {activeLesson.toolkit.includes('Toolkit4') && (
                          <button
                          className={`btn btn-primary  tool-tab ${activeToolkit === 'Toolkit4' ? 'active' : ''}`}
                          onClick={() => setActiveToolkit('Toolkit4')}
                        >
                          {t("Tool")} 4
                        </button>
                        )}
                      {activeLesson.toolkit.includes('Toolkit5') && (
                          <button
                          className={`btn btn-primary  tool-tab ${activeToolkit === 'Toolkit5' ? 'active' : ''}`}
                          onClick={() => setActiveToolkit('Toolkit5')}
                        >
                          {t("Tool")} 5
                        </button>
                        )}
                      {activeLesson.toolkit.includes('Toolkit6') && (
                          <button
                          className={`btn btn-primary  tool-tab ${activeToolkit === 'Toolkit6' ? 'active' : ''}`}
                          onClick={() => setActiveToolkit('Toolkit6')}
                        >
                          {t("Tool")} 6
                        </button>
                        )}
                      {activeLesson.toolkit.includes('Program2Toolkit1') && (
                          <button
                          className={`btn btn-primary  tool-tab ${activeToolkit === 'Program2Toolkit1' ? 'active' : ''}`}
                          onClick={() => setActiveToolkit('Program2Toolkit1')}
                        >
                          {t("Tool")} 1
                        </button>
                        )}
                      {activeLesson.toolkit.includes('Program2Toolkit2') && (
                          <button
                          className={`btn btn-primary  tool-tab ${activeToolkit === 'Program2Toolkit2' ? 'active' : ''}`}
                          onClick={() => setActiveToolkit('Program2Toolkit2')}
                        >
                          {t("Tool")} 2
                        </button>
                        )}
                      {activeLesson.toolkit.includes('Program2Toolkit3') && (
                          <button
                          className={`btn btn-primary  tool-tab ${activeToolkit === 'Program2Toolkit3' ? 'active' : ''}`}
                          onClick={() => setActiveToolkit('Program2Toolkit3')}
                        >
                          {t("Tool")} 3
                        </button>
                        )}
                      {activeLesson.toolkit.includes('Program2Toolkit4') && (
                          <button
                          className={`btn btn-primary  tool-tab ${activeToolkit === 'Program2Toolkit4' ? 'active' : ''}`}
                          onClick={() => setActiveToolkit('Program2Toolkit4')}
                        >
                          {t("Tool")} 4
                        </button>
                        )}
                      {activeLesson.toolkit.includes('Program2Toolkit5') && (
                          <button
                          className={`btn btn-primary  tool-tab ${activeToolkit === 'Program2Toolkit5' ? 'active' : ''}`}
                          onClick={() => setActiveToolkit('Program2Toolkit5')}
                        >
                          {t("Tool")} 5
                        </button>
                        )}
                      {activeLesson.toolkit.includes('PMOSToolkit1') && (
                          <button
                          className={`btn btn-primary  tool-tab ${activeToolkit === 'PMOSToolkit1' ? 'active' : ''}`}
                          onClick={() => setActiveToolkit('PMOSToolkit1')}
                        >
                          {t("Tool")} 1
                        </button>
                        )}
                      {activeLesson.toolkit.includes('PMOSToolkit2') && (
                          <button
                          className={`btn btn-primary  tool-tab ${activeToolkit === 'PMOSToolkit2' ? 'active' : ''}`}
                          onClick={() => setActiveToolkit('PMOSToolkit2')}
                        >
                          {t("Tool")} 2
                        </button>
                        )}
                      {activeLesson.toolkit.includes('PMOSToolkit3') && (
                          <button
                          className={`btn btn-primary  tool-tab ${activeToolkit === 'PMOSToolkit3' ? 'active' : ''}`}
                          onClick={() => setActiveToolkit('PMOSToolkit3')}
                        >
                          {t("Tool")} 3
                        </button>
                        )}
                      {activeLesson.toolkit.includes('Program3Toolkit1') && (
                          <button
                          className={`btn btn-primary  tool-tab ${activeToolkit === 'Program3Toolkit1' ? 'active' : ''}`}
                          onClick={() => setActiveToolkit('Program3Toolkit1')}
                        >
                          {t("Tool")} 1
                        </button>
                        )}
                      {activeLesson.toolkit.includes('Program3Toolkit3') && (
                          <button
                          className={`btn btn-primary  tool-tab ${activeToolkit === 'Program3Toolkit3' ? 'active' : ''}`}
                          onClick={() => setActiveToolkit('Program3Toolkit3')}
                        >
                          {t("Tool")} 3
                        </button>
                        )}
                      {activeLesson.toolkit.includes('Program3Toolkit5') && (
                          <button
                          className={`btn btn-primary  tool-tab ${activeToolkit === 'Program3Toolkit5' ? 'active' : ''}`}
                          onClick={() => setActiveToolkit('Program3Toolkit5')}
                        >
                          {t("Tool")} 5
                        </button>
                        )}
                      {activeLesson.toolkit.includes('IVoTMoneyGameofLife') && (
                          <button
                          className={`btn btn-primary  tool-tab ${activeToolkit === 'IVoTMoneyGameofLife' ? 'active' : ''}`}
                          onClick={() => setActiveToolkit('IVoTMoneyGameofLife')}
                        >
                          IVoT Game Of Life
                        </button>
                        )}
                  </div> */}

                  <div className="tool-content">
                    {activeToolkit === 'Toolkit1' && toolkitData['Toolkit1'] && <ShareCalculatorTool data={toolkitData['Toolkit1']} />}
                    {activeToolkit === 'Toolkit2' && toolkitData['Toolkit2'] && <Toolkit2 data={toolkitData['Toolkit2']} />}
                    {activeToolkit === 'Toolkit3' && toolkitData['Toolkit3'] && <Toolkit3 data={toolkitData['Toolkit3']} />}
                    {activeToolkit === 'Toolkit4' && toolkitData['Toolkit4'] && <Toolkit4 data={toolkitData['Toolkit4']} />}
                    {activeToolkit === 'Toolkit5' && toolkitData['Toolkit5'] && <Toolkit5 data={toolkitData['Toolkit5']} />}
                    {activeToolkit === 'Toolkit6' && toolkitData['Toolkit6'] && <Toolkit6 data={toolkitData['Toolkit6']} />}
                    {activeToolkit === 'Program2Toolkit1' && toolkitData['Program2Toolkit1'] && <Program2Toolkit1 data={toolkitData['Program2Toolkit1']} />} 
                    {activeToolkit === 'Program2Toolkit2' && toolkitData['Program2Toolkit2'] && <Program2Toolkit2 data={toolkitData['Program2Toolkit2']} />} 
                    {activeToolkit === 'Program2Toolkit3' && toolkitData['Program2Toolkit3'] && <Program2Toolkit3 data={toolkitData['Program2Toolkit3']} />} 
                    {activeToolkit === 'Program2Toolkit4' && toolkitData['Program2Toolkit4'] && <Program2Toolkit4 data={toolkitData['Program2Toolkit4']} />} 
                    {activeToolkit === 'Program2Toolkit5' && toolkitData['Program2Toolkit5'] && <Program2Toolkit5 data={toolkitData['Program2Toolkit5']} />} 
                    {activeToolkit === 'PMOSToolkit1' && toolkitData['PMOSToolkit1'] && <PMOSToolkit1 data={toolkitData['PMOSToolkit1']} />} 
                    {activeToolkit === 'PMOSToolkit2' && toolkitData['PMOSToolkit2'] && <PMOSToolkit2 data={toolkitData['PMOSToolkit2']} />} 
                    {activeToolkit === 'PMOSToolkit3' && toolkitData['PMOSToolkit3'] && <PMOSToolkit3 data={toolkitData['PMOSToolkit3']} />}
                    {activeToolkit === 'Program3Toolkit1' && toolkitData['Program3Toolkit1'] && <Program3Toolkit1 data={toolkitData['Program3Toolkit1']} />}
                    {activeToolkit === 'Program3Toolkit3' && toolkitData['Program3Toolkit3'] && <Program3Toolkit3 data={toolkitData['Program3Toolkit3']} />}
                    {activeToolkit === 'Program3Toolkit5' && toolkitData['Program3Toolkit5'] && <Program3Toolkit5 data={toolkitData['Program3Toolkit5']} />}
                    {activeToolkit === 'IVoTMoneyGameofLife' && toolkitData['IVoTMoneyGameofLife'] && <IVoTMoneyGameofLife data={toolkitData['IVoTMoneyGameofLife']} />}
                  </div>

                </div>
              )}  




                {/* Include */} 
                {activeTab === 'overview' && (trainingProgram?.number_of_lessons >= 1) &&  
                   <div className="sidebar-sec">
                   <CourseContent
                     trainingProgram={trainingProgram}
                     onStartLesson={addLessonTab}
                     fetchLessonStatuses={fetchLessonStatuses}
                   />
                 </div>
                }
                {/* /Include */}
                
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

DetailsContent.propTypes = {
  trainingProgram: PropTypes.object.isRequired, 
  percentCompletion: PropTypes.number,
  fetchLessonStatuses: PropTypes.func.isRequired,
};

export default DetailsContent;


