
import React, { useState, useEffect } from "react";
// import { Play } from "../../../imagepath";
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getCourses } from "../../../../api/programApi";
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import { ShimmerThumbnail } from "react-shimmer-effects";


const CourseContent = ({ onStartLesson, trainingProgram,fetchLessonStatuses }) => {
  const { t } = useTranslation();
  const [openCourse, setOpenCourse] = useState(null); // Track which course is open
  const [courses, setCourses] = useState([]);
  const [lessons, setLessons] = useState([]);
  const [lessonStatus, setLessonStatus] = useState([]); // Track user lesson status

  const [activeLesson, setActiveLesson] = useState(null); // Track active lesson
  const [expandedLesson, setExpandedLesson] = useState(null);

  const [loading, setLoading] = useState(true);

  

  const highlightActiveLesson = (lesson) => {
  const lessonStatusObj = lessonStatus.find((status) => status.lesson_id === lesson.srno);
  const lessonStatusText = lessonStatusObj ? lessonStatusObj.status : null;

      
  if (lessonStatusText === "Completed") {
    return "completed-lesson"; // Gray out completed lessons
  } 
  
  if (!lessonStatusText || lessonStatusText === "Pending") {
       // If lessonStatusText is null/undefined, treat it as pending
       return lesson.srno === activeLesson?.srno ? "active-lesson" : "";
  } 
   return "";
};


  const trainingStatus = useSelector((state) => state.training.trainingStatus);
 
  const fetchCourses = async () => {
    const coursesData = await getCourses(trainingProgram.srno);

    if (coursesData?.data?.courses) {
      setCourses(coursesData.data.courses);
    }
    if (coursesData?.data?.lessons) {
      setLessons(coursesData.data.lessons);
      setLoading(false);
    }
  };

  // Fetch Lessons
  useEffect(() => {

    fetchCourses();
  }, [trainingProgram]);


  useEffect(() => {
    const updateLessonStatus = async () => {
      if (openCourse) {
        try {
          const statusData = await fetchLessonStatuses(openCourse); // Use the prop function
         
          if (statusData) {
            setLessonStatus(statusData); // Update the lesson status with the response
          }
        } catch (error) {
          console.error("Error updating lesson status:", error);
        }
      }
    };
  
    updateLessonStatus(); // Call the function to update lesson status
  }, [openCourse, fetchLessonStatuses]);

  useEffect(() => {
    if (courses.length > 0 && openCourse === null) {
      setOpenCourse(courses[0].srno); // Default to the first course
    }
  }, [courses]);
  
  return  (
    <div className="card content-sec position-relative shadow-effect">
      <div className="card-body">
        <div className="row">
          <div className="col-sm-6">
            <h2 className="subs-title">{t("getStarted")}!</h2>
          </div>
        </div>
        <div className="course-card">
          <ul>

          {loading ? (<ShimmerThumbnail height={250} rounded />)
          :
            lessons.length > 0 ? (
            lessons.map((lesson, index) => {
              const isLastLesson = index === lessons.length - 1;
  
              // Find the status of the current lesson
              const lessonStatusObj = lessonStatus.find((status) => status.lesson_id === lesson.srno);
              const lessonStatusText = lessonStatusObj ? lessonStatusObj.status : null;
  
              // Find the status of the previous lesson
              const prevLessonStatusObj =
                index > 0 ? lessonStatus.find((status) => status.lesson_id === lessons[index - 1].srno) : null;
              const prevLessonStatusText = prevLessonStatusObj ? prevLessonStatusObj.status : null;
  
              return (
                <li
                  className={`lesson-list-item ${highlightActiveLesson(lesson)}`}
                  key={lesson._id.$oid}
                  onClick={() => {
                    setExpandedLesson((prev) => (prev?.srno === lesson.srno ? null : lesson));
                    setActiveLesson({
                      ...lesson,
                      isLastLesson,
                      lessonStatusObj,
                      lessonStatusText,
                      prevLessonStatusObj,
                      prevLessonStatusText,
                      index, // Pass the current index
                    });
                  }}
                >
                  <p>
                    <svg
                      className={`me-2 ${activeLesson?.srno === lesson.srno ? "active-icon" : "icon-default"}`}
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M18.7 8.98001L4.14 17.71C4.05 17.38 4 17.03 4 16.67V7.33001C4 4.25001 7.33 2.33001 10 3.87001L14.04 6.20001L18.09 8.54001C18.31 8.67001 18.52 8.81001 18.7 8.98001Z"
                        fill="currentColor"
                      />
                      <path
                        opacity="0.4"
                        d="M18.0897 15.46L14.0397 17.8L9.99973 20.13C8.08973 21.23 5.83973 20.57 4.71973 18.96L5.13973 18.71L19.5797 10.05C20.5797 11.85 20.0897 14.31 18.0897 15.46Z"
                        fill="currentColor"
                      />
                    </svg>
  
                    <span>{lesson.lesson_header}</span>
                  </p>
                  {expandedLesson?.srno === lesson.srno && (
                    <div className="lesson-action-buttons mt-2">
                      {activeLesson.lessonStatusText === null && activeLesson.index === 0 &&  (
                        <Link
                          className="lesson-button"
                          to="#"
                          onClick={(e) => {
                            e.preventDefault();
                            onStartLesson(activeLesson, activeLesson.isLastLesson, openCourse);
                          }}
                        >
                          {t("start")}
                        </Link>
                      )}
                      {activeLesson.lessonStatusText === null &&
                        activeLesson.prevLessonStatusText === "Completed" &&  (
                          <Link
                            className="lesson-button"
                            to="#"
                            onClick={(e) => {
                              e.preventDefault();
                              onStartLesson(activeLesson, activeLesson.isLastLesson, openCourse);
                            }}
                          >
                            {t("start")}
                          </Link>
                        )}
  
                      {trainingStatus === "Completed" &&
                        activeLesson.lessonStatusText === null &&  (
                          <Link
                            className="lesson-button"
                            to="#"
                            onClick={(e) => {
                              e.preventDefault();
                              onStartLesson(activeLesson, activeLesson.isLastLesson, openCourse);
                            }}
                          >
                            {t("start")}
                          </Link>
                        )}
  
                      {activeLesson.lessonStatusText === null &&
                        activeLesson.prevLessonStatusText !== "Completed" &&
                        activeLesson.index !== 0 && <span>{t("nextLevel")}...</span>}
  
                      {activeLesson.lessonStatusText &&
                        activeLesson.lessonStatusText !== "Completed" &&  (
                          <Link
                            className="lesson-button"
                            to="#"
                            onClick={(e) => {
                              e.preventDefault();
                              onStartLesson(activeLesson, activeLesson.isLastLesson);
                            }}
                          >
                            {t("start")}
                          </Link>
                        )}
  
                      {activeLesson.lessonStatusText === "Completed" &&
                        activeLesson.allow_revisit === "Yes" &&  (
                          <Link
                            className="lesson-button"
                            to="#"
                            onClick={(e) => {
                              e.preventDefault();
                              onStartLesson(activeLesson, activeLesson.isLastLesson);
                            }}
                          >
                            {t("revisit")}
                          </Link>
                        )}
                    </div>
                  )}
                </li>
              );
            })
          ) : (
            <li>{t("noLessonsAvailableForThisCourse")}</li>
          )}
          </ul>


        </div>

      </div>
    </div>
  );
  
};

CourseContent.propTypes = {
  trainingProgram: PropTypes.shape({
    srno: PropTypes.number.isRequired,
    allow_revisit: PropTypes.string.isRequired,
  }).isRequired,
  onStartLesson: PropTypes.func.isRequired,
  fetchLessonStatuses: PropTypes.func.isRequired,
};

export default CourseContent;

