import { configureStore } from '@reduxjs/toolkit';
import sidebarReducer from './sidebarSlice';
import userReducer from './userSlice';
import trainingReducer from './trainingSlice'; 
import partnerReducer from './partnerSlice';
import confettiReducer from "./confettiSlice";
import financeReducer from "./financeSlice";

const store = configureStore({
  reducer: {
    sidebarSlice: sidebarReducer,
    userSlice:userReducer,
    training: trainingReducer,
    partner: partnerReducer,
    confetti: confettiReducer,
    finance: financeReducer,
  },
});

export default store;
