import "./tool.css";
import React,{useState,useEffect} from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { formatNumberWithCommasAndMillions,handlePercentageChange,stripPercentage } from "../../../../../utils/helper";
import {calculateProgram2Toolkit3} from "../../../../../api/programApi";

const Program2Toolkit3 = ({ data }) => {

    const [yearOverForecast, setYearOverForecast] = useState("0.0");
    const [calculatedData, setCalculatedData] = useState(null);

      useEffect(() => {
        const fetchInitialData = async () => {
          if (data) {
            const yearOverForecastValue = stripPercentage(yearOverForecast);
            const initialCalculatedData = await calculateProgram2Toolkit3(yearOverForecastValue);
            setCalculatedData(initialCalculatedData);
          }
        };
        fetchInitialData();
      }, [data]);
  
    const handleBlur = async () => {
      try {
        const yearOverForecastValue = stripPercentage(yearOverForecast);
        const response = await calculateProgram2Toolkit3(yearOverForecastValue);

        setCalculatedData(response);
      } catch (error) {
        console.error("Error calculating forecast", error);
      }
    };
 
  const userName = useSelector((state) => state.userSlice.name);

  const getField = (fieldName) => {
    const field = data?.data?.find((item) => item.field_name === fieldName);
    if (field && field.toolkit_label_status === "Inactive") {
      return '';
    }
    return field ? field.field_value : "";
  };


  return (
    <div className="share-calculator toolkit4-container">
      <div className="toolkit4-content">
        {/* Header Row */}
        <h3 className="background-blue font-white tool-header">
          <i className="fas fa-calculator"></i> {getField("toolheader_header")}
        </h3>

        {/* Combined Table */}
        <table className="tool-table">
          <thead>
            <tr>
              <th>{getField("name")}</th>
              <th>{getField("symbol")}</th>
              <th>{getField("latest")}</th>
              <th>{getField("next_report")}</th>
            </tr>
          </thead>
          <tbody>
            {/* Initial Rows */}
            <tr>
              <td className="td-tool-value">{getField("company_name")}</td>
              <td className="td-tool-value">{getField("symbol_name")}</td>
              <td className="td-tool-value">{getField("latest_value")}</td>
              <td className="td-tool-value">{getField("next_report_value")}</td>
            </tr>

            {/* Analyst Information */}
            <tr>
              <th>{getField("analyst")}</th>
              <th>{getField("shares")}</th>
              <th>{getField("market_px")}</th>
              <th>{getField("market_cap")}</th>
            </tr>
            <tr>
              <td className="exchange-rate-result">{userName}</td>
              <td className="td-tool-value">{formatNumberWithCommasAndMillions(Number(getField("shares_value")))}</td>
              <td className="td-tool-value">${getField("market_px_value")}</td>
              <td className="td-tool-value">${formatNumberWithCommasAndMillions(Number(getField("market_cap_value")))}</td>
            </tr>

            {/* Section Header */}
            <tr>
              <td colSpan="4" className="toolkit-full-row-header">
                {getField("lesson_header")}
              </td>
            </tr>

            {/* Column Headers */}
            <tr>
              <th></th>
              <th>{getField("year")}</th>
              <th>{getField("baseline")}</th>
              <th>{getField("forecast")}</th>
            </tr>

            {/* Additional Rows */}
            <tr>
              <td>{getField("quarterly_income_statement")}</td>
              <td className="td-tool-value">{getField("quarterly_year")}</td>
              <td className="td-tool-value">{getField("quarterly_baseline")}</td>
              <td className="td-tool-value">{getField("quarterly_forecast")}</td>
            </tr>
           
            <tr>
              <td>{getField("sales")}</td>
              <td className="td-tool-value">${formatNumberWithCommasAndMillions(Number(getField("sales_year")))}</td>
              <td className="td-tool-value">${formatNumberWithCommasAndMillions(Number(getField("sales_baseline")))}</td>
              <td className="exchange-rate-result">${formatNumberWithCommasAndMillions(Number(calculatedData?.sales_forecast))}</td>
            </tr>
            <tr>
              <td>{getField("year_over")}</td>
              <td></td>
              <td className="td-tool-value">{formatNumberWithCommasAndMillions(Number(calculatedData?.year_over_baseline))}%</td>
              <td><input
                className="form-input"
                type="text"
                value={yearOverForecast}
                onChange={handlePercentageChange(setYearOverForecast)}
                onBlur={handleBlur}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

Program2Toolkit3.propTypes = {
  data: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        field_name: PropTypes.string.isRequired,
        field_value: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
};

export default Program2Toolkit3;
