import React,{useEffect, useState} from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import {CalculateProgram3Toolkit1} from "../../../../../api/programApi";
import {formatNumberWithCommasAndMillions } from "../../../../../utils/helper";
import "./tool.css";

const Program3Toolkit1 = ({data }) => {
    
    const [logoUrl, setLogoUrl] = useState("");
    const [logoLoading, setLogoLoading] = useState(true);
    const [calculatedValue, setCalculatedValue] = useState(null);
    const userName = useSelector((state) => state.userSlice.name);

    
    const getField = (fieldName) => {
        const field = data?.data?.find((item) => item.field_name === fieldName);
        if (field && field.toolkit_label_status === "Inactive") {
          return '';
        }
        return field ? field.field_value : "";
      };

      useEffect(() => {
        setLogoLoading(true);
        const fetchInitialData = async () => {
          if (data) {
            const initialCalculatedData = await CalculateProgram3Toolkit1(); 
            try{
              setCalculatedValue(initialCalculatedData);
              setLogoUrl(initialCalculatedData?.company_logo);
            } catch(error){
              console.error("Error fetching initial data", error);
            } finally {
              setLogoLoading(false);
            }
          }
        };
        fetchInitialData();
      }, []);


  return (
    <div className="share-calculator toolkit4-container">
      <div className="toolkit4-content">

        {/* Table */}
        <table className="tool-table">
        <thead>
            <tr>
              <th className="background-color-white center-image"  colSpan="4">
              {logoLoading ? (
                        <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                        </div>
                    ) : (
                        <img src={logoUrl} alt="Logo" className="img-fluid" />
                    )}
              </th>
            </tr>
            <tr>
                <th className="background-color-white text-center"  colSpan="4">
                    <h4>{getField("tool_header")}</h4>
                </th>
            </tr>
        </thead>

          {/* Company Information */}
          <thead>
            <tr>
              <th>{getField("company")}</th>
              <th>{getField("symbol")}</th>
              <th>{getField("latest")}</th>
              <th>{getField("next_report")}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="td-tool-value">{calculatedValue?.company_value}</td>
              <td className="td-tool-value">{calculatedValue?.symbol_value}</td>
              <td className="td-tool-value">{getField("latest_value")}</td>
              <td className="td-tool-value">{getField("next_report_value")}</td>
            </tr>

            {/* Analyst */}
            <tr>
              <th>{getField("analyst")}</th>
              <th>{getField("shares")}</th>
              <th>{getField("price")}</th>
              <th>{getField("mkt_cap")}</th>
            </tr>
            <tr>
              <td className="exchange-rate-result">{userName}</td>
              <td className="td-tool-value">{formatNumberWithCommasAndMillions(Number(getField("shares_value")))}</td>
              <td className="exchange-rate-result">{calculatedValue?.price_value !== undefined ? `$${formatNumberWithCommasAndMillions(calculatedValue?.price_value)}` : ""}</td>
              <td className="exchange-rate-result">{calculatedValue?.mkt_cap_value !== undefined ? `$${formatNumberWithCommasAndMillions(calculatedValue?.mkt_cap_value)}` : ""}</td>
            </tr>
          </tbody>

          {/* Section Header */}
          <thead>
            <tr>
              <th colSpan="4" className="background-deep-blue font-white  text-center">
                {getField("lesson_header")}
              </th>
            </tr>
          </thead>

          {/* Valuation Summary */}
          <thead>
            <tr >
              <th>{getField("valuation_summary")}</th>
              <th>{getField("year_ago_label")}</th>
              <th>{getField("baseline_label")}</th>
              <th>{getField("forecast_label")}</th>
            </tr>
          </thead>
          <thead>
            <tr>
              <th></th>
              <td className="td-tool-value">{getField("year_ago")}</td>
              <td className="td-tool-value">{getField("baseline")}</td>
              <td className="td-tool-value">{getField("forecast")}</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>{getField("quarterly_operating")}</th>
              <td className="td-tool-value">${formatNumberWithCommasAndMillions(Number(getField("quarterly_operating_quarter_23")))}</td>
              <td className="td-tool-value">${formatNumberWithCommasAndMillions(Number(getField("quarterly_operating_quarter_24")))}</td>
              <td className="td-tool-value">${formatNumberWithCommasAndMillions(Number(getField("quarterly_operating_quarter_27")))}</td>

            </tr>
            <tr>
              <th>{getField("operating_cash")}</th>
              <td className="td-tool-value">{getField("operating_cash_quarter_23")}%</td>
              <td className="td-tool-value">{getField("operating_cash_quarter_24")}%</td>
              <td className="td-tool-value">{getField("operating_cash_quarter_27")}%</td>

            </tr>
            <tr>
              <th>{getField("enterprise")}</th>
              <td className="td-tool-value">${formatNumberWithCommasAndMillions(Number(getField("enterprise_quarter_23")))}</td>
              <td className="td-tool-value">${formatNumberWithCommasAndMillions(Number(getField("enterprise_quarter_24")))}</td>
              <td className="td-tool-value">${formatNumberWithCommasAndMillions(Number(getField("enterprise_quarter_27")))}</td>

            </tr>
            <tr>
              <th>{getField("implied")}</th>
              <td className="exchange-rate-result">{calculatedValue?.implied_quarter_23 !== undefined ? `${formatNumberWithCommasAndMillions(calculatedValue?.implied_quarter_23)}%` : ""}</td>
              <td className="exchange-rate-result">{calculatedValue?.implied_quarter_24 !== undefined ? `${formatNumberWithCommasAndMillions(calculatedValue?.implied_quarter_24)}%` : ""}</td>
              <td className="exchange-rate-result">{calculatedValue?.implied_quarter_27 !== undefined ? `${formatNumberWithCommasAndMillions(calculatedValue?.implied_quarter_27)}%` : ""}</td>
            </tr>
          </tbody>


        </table>
      </div>
    </div>
  );
};

Program3Toolkit1.propTypes = {
    data: PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape({
          field_name: PropTypes.string.isRequired,
          field_value: PropTypes.string.isRequired,
        })
      ).isRequired,
    }).isRequired,
  };

export default Program3Toolkit1;
