import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

const pageNames = {
    '/home': 'Landing Page',
    '/register': 'Registration Page',
    '/login': 'Login Page',
    '/student/student-dashboard': 'Dashboard Page',
    '/student/students-profile': 'Profile Page',
    '/student/student-toolbox': 'Toolbox Page',
    '/student/student-quiz': 'Onboarding Quiz',
  };
  

const TrackPageView = () => {
  const location = useLocation();

  useEffect(() => {
    const userEmail = localStorage.getItem("Email") || "Anonymous";
    const pageName = pageNames[location.pathname] || 'Unknown Page';
    ReactGA.send({ hitType: 'pageview', page: location.pathname , user: {
        email: userEmail, // Custom dimension or event parameter
      } });
    ReactGA.event({
      category: 'Page Visit',
      action: `Visited ${pageName}`,
      label: userEmail,
    });
  }, [location]);

  return null;
};

export default TrackPageView;
