import React from "react";
import PropTypes from "prop-types";
import PMOSToolkitOne from "./PMOSToolkitOne";
import PMOSToolkitTwo from "./PMOSToolkitTwo";
import { ShimmerSimpleGallery } from "react-shimmer-effects";

const PersonalMoneyTools = ({ toolkitData1, toolkitData2 }) => {


    
  return (
    
      <div className="row">
        {/* Render PMOSToolkit1 */}
        <div className="col-md-4">
          {toolkitData1 ? (
              <PMOSToolkitOne data={toolkitData1} />

          ) : (
            <ShimmerSimpleGallery row={1} col={1} imageHeight={350} />
          )}
        </div>

        {/* Render PMOSToolkit2 */}
          <div className="col-md-5">
          {toolkitData2 ? (

              <PMOSToolkitTwo data={toolkitData2} />
          ) : (
            <ShimmerSimpleGallery row={1} col={1} imageHeight={350} />
          )}
        </div>
      </div>
    
  );
};

PersonalMoneyTools.propTypes = {
  toolkitData1: PropTypes.object,
  toolkitData2: PropTypes.object,
};

export default PersonalMoneyTools;
