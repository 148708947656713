import React from "react";
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Chapter, Chart, Timer2 } from "../../../imagepath";
import {completeOverviewTrainingProgram} from "../../../../api/programApi";


const CourseOverview = ({trainingProgram}) => {

  const navigate = useNavigate();
  const email = localStorage.getItem("Email")
  const trainingProgramId = trainingProgram?.srno;

  const handleCompleteOverview = async () => {
    await completeOverviewTrainingProgram(email, trainingProgramId);
    navigate("/student/student-dashboard")
  };
  
  const { t } = useTranslation();
  return (
    <>
      <div className="card overview-sec shadow-effect">
                <div className="card-body">
                  {(trainingProgram?.tags !== "Onboarding") &&  <h2 className="subs-title">{t('programTitle')}</h2> }
                  
                  

                  {(trainingProgram?.tags !== "Onboarding") && 
                                    <div className="row includes-row">
                                    <div className="col-12">
                                      <ul className="d-flex gap-2 flex-wrap">
                                        <li>
                                          <img
                                            src={Timer2}
                                            className="me-2"
                                            alt={t('duration')}
                                          />
                                          {t('duration')}: <span>{trainingProgram && trainingProgram.duration}</span>
                                        </li>
                                        <li>
                                          <img
                                            src={Chapter}
                                            className="me-2"
                                            alt={t('chapters')}
                                          />
                                          {t('chapters')}: <span>{trainingProgram && trainingProgram.number_of_lessons}</span>
                                        </li>
                                        <li>
                                          <img
                                            src={Chart}
                                            className="me-2"
                                            alt={t('level')}
                                          />
                                          {t('level')}: <span>{trainingProgram && t(trainingProgram.program_level)}</span>
                                        </li>
                                      </ul>
                                  </div>
                                  </div>
                  }


                  <div className="content-container-overview mt-3" dangerouslySetInnerHTML={{ __html: trainingProgram?.program_overview }} />
                  {/* Move to Next Course Button (Visible only for Onboarding courses) */}
                    {trainingProgram?.tags === "Onboarding" && (
                      <div className="text-center mt-4">
                        <button className="btn btn-start bg-ivot-primary" onClick={handleCompleteOverview}>
                          {t("Move to Next Course")}
                        </button>
                      </div>
                    )}
                  </div>
      </div>
    </>
  );
};

CourseOverview.propTypes = {
  trainingProgram: PropTypes.shape({
    program_overview: PropTypes.string.isRequired, 
    duration: PropTypes.string.isRequired,
    number_of_lessons: PropTypes.number.isRequired,
    program_level: PropTypes.string.isRequired,
    tags:PropTypes.string.isRequired,
    srno:PropTypes.number.isRequired
    
  }).isRequired, 
};
export default CourseOverview;


