import React, { useState,useEffect } from "react";
import Header from "../header";
import { ShimmerPostList } from "react-shimmer-effects";
import { useLocation } from 'react-router-dom';
import StudentSidebar from "../sidebar";
import { quiz01} from "../../imagepath";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setTrainingPrograms,
  // setStatus,
  // setError,
} from "../../common/redux/trainingSlice";
import { getTrainingPrograms,getUsersAllTrainingProgramPercent } from "../../../api/programApi";
import { getProfileQuizSubmissionByEmail,getStudentDetails,getQuizByType} from "../../../api/studentApi";
import { useNavigate } from "react-router-dom";
import { setUserAvatar , setUserName,setUserSrno} from "../../common/redux/userSlice";
import { useTranslation } from "react-i18next";
import Confetti from 'react-confetti'
import { hideConfetti } from "../../../components/common/redux/confettiSlice";
import { useWindowSize } from 'react-use';


const StudentDashboard = () => {
  const { width, height } = useWindowSize();
  const showConfetti = useSelector((state) => state.confetti.show);
  const location = useLocation();
  const { t } = useTranslation();
  const [quizCardImage, setQuizCardImage] = useState(null);

 
  const navigate = useNavigate();

  // const campaign = localStorage.getItem("campaign") || "Default";
  // const quizDescriptionText = {
  //   "Pilot_A": t("onboardingQuizDescription"),
  //   "Pilot_C": t("onboardingQuizDescriptionCampaignC"),
  //   "Default": t("onboardingQuizDescription"),
  // };

  // const onboardingQuizDescriptionKey = quizDescriptionText[campaign];

  const handlePayToUnlock = () => {
    // Navigate to the pricing plan page
    navigate('/pricing-plan2');
  };

    
  const startQuiz = () => {
    navigate("/student/student-quiz", { state: { quizType: "Onboarding Quiz" } });
  }
  // const endQuiz = () => {
  //   navigate("/student/student-quiz", { state: { quizType: "Completion Quiz" } });
  // }

  const [percentData, setPercentData] = useState([]);
 

  const [quizSubmission, setQuizSubmission] = useState(null);
  const [loadingPrograms, setLoadingPrograms] = useState(true);

  const [initialFetchDone, setInitialFetchDone] = useState(false); // Track the initial render fetch
 

  const dispatch = useDispatch();
  const allTrainingPrograms = useSelector((state) => state.training.details);
  



  

    const getPercentCompletion = (trainingId) => {
      const userEmail = localStorage.getItem("Email");
      if (!percentData || !userEmail) {
        return 0;  
      }  
    
      const foundProgram = percentData?.find(
        (program) => program.training_id === trainingId && program.user_email === userEmail
      );
    
      return foundProgram ? foundProgram.percent_completion : 0;
    };
 
    const isTrainingProgramDisabled = (trainingProgram, index) => {
      
      const onboardingQuizDone = quizSubmission?.onboarding_quiz === 'yes';
      const trainingProgramCompleted = getPercentCompletion(trainingProgram?.srno) === 100;
      const trainingProgramAllowRevisit = trainingProgram.allow_revisit === "Yes";

      // Check if the training program is locked based on product_ids and is_subscribed
      if (trainingProgram?.product_ids && trainingProgram?.product_ids?.length > 0) {
        if (!trainingProgram?.is_subscribed) {
          return true; // Locked program (premium but not subscribed)
        }
      } 
    
      // If onboarding quiz is not done, disable all training programs
      if (!onboardingQuizDone) {
        return true;
      }
    
      // If it's the first program and completed with allow_revisit = "No", disable it
      if (index === 0) {
        if (trainingProgramCompleted && !trainingProgramAllowRevisit) {
          return true; // Disable if completed and revisits not allowed
        }
        return false; // Enable if not completed or revisits allowed
      }
    
      // For subsequent programs, enable if the previous program is completed
      const previousTrainingProgram = allTrainingPrograms[index - 1];
      const previousTrainingProgramCompleted = getPercentCompletion(previousTrainingProgram?.srno) === 100;
    
      // Enable the current program only if the previous one is completed
      if (previousTrainingProgramCompleted) {
        if (trainingProgramCompleted && !trainingProgramAllowRevisit) {
          return true; // Disable if completed and revisits not allowed
        }
        return false; // Enable if revisits allowed or not yet completed
      }
    
      return true; // Default to disabled
    };
  
  
 
    useEffect(() => {

      const fetchStudentDetails = async () => {
    try {
      
        const cachedUserLanguage = localStorage.getItem("userLanguage");
        const cachedUserAvatar = localStorage.getItem("userAvatar");
        const cachedTrainingPrograms = localStorage.getItem("allTrainingPrograms");

        if (cachedUserLanguage && cachedUserAvatar && cachedTrainingPrograms) {
    
          dispatch(setUserAvatar(cachedUserAvatar)); // Set userAvatar in Redux
          dispatch(setTrainingPrograms(JSON.parse(cachedTrainingPrograms))); // Set training programs in Redux
          setLoadingPrograms(false);
          return;
        }
      // If not available, make API calls to fetch data
      const email = localStorage.getItem("Email");
    
      const studentData = await getStudentDetails(email);

      if (studentData?.data?.data?.length > 0) {
        const userLanguage = studentData.data.data[0]?.language;
        const userAvatar = studentData.data.data[0]?.avatar?.[0];
        const userFirstName = studentData.data.data[0]?.first_name;
        const userLastName = studentData.data.data[0]?.last_name;
        const userSrno = studentData.data.data[0]?.srno;
        // Update Redux state and localStorage
        const fullName = `${userFirstName} ${userLastName}`;
        dispatch(setUserName(fullName));
        dispatch(setUserSrno(userSrno));


        // setDemoUser(studentData.data.data[0]?.demo_user)

        if (userLanguage) {
          localStorage.setItem('userLanguage', userLanguage);
        }

        if (userAvatar) {
          dispatch(setUserAvatar(userAvatar));
        }

        // 2. Fetch training programs based on user language
        const trainingPrograms = await getTrainingPrograms(userLanguage,email);
            
        if (trainingPrograms) {      
          dispatch(setTrainingPrograms(trainingPrograms));
          setLoadingPrograms(false);
        }
      } else {
        console.log("No data found.");
        setLoadingPrograms(false); 
      }
    } catch (error) {
      console.error("Error fetching student details or training programs:", error);
      setLoadingPrograms(false);
    }
        };
      fetchStudentDetails();
    }, [dispatch]);
  

    useEffect(() => {
      const email = localStorage.getItem("Email");
      const fetchQuizSubmission = async () => {
        if (!email) return;
    
        try {
          const cachedQuizData = localStorage.getItem("quizSubmissionData");
          
          if (cachedQuizData && !location.state?.refreshQuizSubmission) {
            setQuizSubmission(JSON.parse(cachedQuizData));
            
          } else {
            const quizData = await getProfileQuizSubmissionByEmail(email);
    
            if (quizData && quizData?.data) {
              setQuizSubmission(quizData?.data);
              //localStorage.setItem("quizSubmissionData", JSON.stringify(quizData?.data));
            }
          }
        } catch (error) {
          console.error("Error fetching quiz submission:", error);
        }
      };
    
      // Always fetch if state indicates refresh is needed or on first load
      if (!initialFetchDone || location.state?.refreshQuizSubmission) {
        fetchQuizSubmission();
        setInitialFetchDone(true); 
      }
    
      // Clear localStorage after quiz completion (if this is triggered in your app)
      if (location.state?.quizCompleted) {
        localStorage.removeItem("quizSubmissionData");
      }
    }, [location.state, initialFetchDone]);
    
  

    // 4. Fetch user's training progress data (percent complete)
    useEffect(() => {
      const fetchTrainingPercentData = async () => {
        try {
          const userEmail = localStorage.getItem("Email");
          const data = await getUsersAllTrainingProgramPercent(userEmail);
          
          if(data && data?.data){
          setPercentData(data?.data);}
        } catch (error) {
          console.error("Failed to fetch percent data", error);
        }
      };
      fetchTrainingPercentData();
    }, []);

    

    useEffect(() => {
      const userEmail = localStorage.getItem("Email");
      const language = localStorage.getItem("userLanguage");
      const quizType = "Onboarding Quiz";
      const fetchQuizImage = async () => {
        try {
          if (language && userEmail) {
            const quizData = await getQuizByType(quizType, language, userEmail);            
            if (quizData?.data?.length > 0) {
              setQuizCardImage(quizData.data[0].card_image[0]);
            } else {
              console.error('No quiz data found');
            }
          } else {
            console.error('Language or Email not found');
          }
        } catch (error) {
          console.error('Error fetching quiz:', error);
        }
      };
    
      fetchQuizImage();
    }, [localStorage.getItem("userLanguage"), localStorage.getItem("Email")]); 
    
    

  return (
    <>
      {showConfetti && (
          <Confetti
            recycle={false}
            numberOfPieces={1000} 
            width={width} 
            height={height}
            gravity={0.5} 
            onConfettiComplete={() => dispatch(hideConfetti())}
          />
        )}
      {/* Main Wrapper */}
      <div className="main-wrapper">
        {/* Header */}
        <Header/>
        <div className="container only-display-web">
						<div className="row ">&nbsp;</div>
						<div className="row ">&nbsp;</div>
						<div className="row ">&nbsp;</div>
				</div>
        {/* /Header */}

        {/* Breadcrumb */}
        {/* <div className="breadcrumb-bar breadcrumb-bar-info only-display-web">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-12">
                <div className="breadcrumb-list">
                  <h2 className="breadcrumb-title">{t('dashboard')}</h2>
                  <nav aria-label="breadcrumb" className="page-breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/student/student-dashboard">{t('home')}</Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        {t('dashboard')}
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* /Breadcrumb */}
        {/* Page Content */}
        <div className="page-content bg-light-grayish-blue" >
          <div className="container">
            <div className="row">
              {/* sidebar */}
              <StudentSidebar />
              {/* /Sidebar */}  
              {/* Student Dashboard */}
              <div className="col-xl-9 col-lg-9 container-with-margin" >
                {/* Dashboard Grid */}
                {/* <div className="row justify-content-center">
                                    <div className="col-lg-4 col-md-6 d-flex">
                                        <div className="card dash-info flex-fill">
                                            <div className="card-body">
                                                <h5>Enrolled Courses</h5>
                                                <h2>12</h2>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 d-flex">
                                        <div className="card dash-info flex-fill">
                                            <div className="card-body">
                                                <h5>Active Courses</h5>
                                                <h2>03</h2>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 d-flex">
                                        <div className="card dash-info flex-fill">
                                            <div className="card-body">
                                                <h5>Completed Courses</h5>
                                                <h2>13</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                {/* /Dashboard Grid */}
                {/* <div className="dashboard-title">
                                    <h4>Recently Enrolled Courses</h4>
                                </div> */}
                <div className="row">
                  
                  {/* Course Grid */}

                  {loadingPrograms ? (
  <>
    <ShimmerPostList postStyle="STYLE_FOUR" col={3} row={1} gap={30} />
    <ShimmerPostList postStyle="STYLE_FOUR" col={3} row={1} gap={30} />
    <ShimmerPostList postStyle="STYLE_FOUR" col={3} row={1} gap={30} />
  </>
) : (
  <>
     {/* Onboarding Quiz Card */}
                    <div className={`col-lg-4 col-md-6 col-sm-12 d-flex ${quizSubmission?.onboarding_quiz === 'no' ?  '' : 'hidden-card'}`}>
                    <div className={`course-box flex-fill`}>
                      <div className="product">
                        <div className="product-img ">
                          <Link onClick={(e) => {
                              e.preventDefault();  // Prevent default link behavior
                              startQuiz();
                            }}>
                            <img
                              className="img-fluid "
                              alt="Img"
                              src={quizCardImage || quiz01}
                            />
                          </Link>
                          {/* <div className="price">
                            <h3>{t('onboardingQuiz')}</h3>
                          </div> */}
                        </div>
                        <div className="product-content">
                          <h3 className="title instructor-text">
                            <Link onClick={(e) => {
                                  e.preventDefault();  // Prevent default link behavior
                                  startQuiz();
                                }}>{t('onboardingQuiz')}</Link>
                          </h3>
                          {/* <p className="summary">{onboardingQuizDescriptionKey}</p> */}
                          {/* <div className="course-info d-flex align-items-center">
                            <div className="rating-img d-flex align-items-center">
                              <img src={Icon1} alt="Img" />
                              <p>1 {t('lesson')}</p>
                            </div>
                            <div className="course-view d-flex align-items-center">
                              <img src={Icon2} alt="Img" />
                              <p>{t('duration')}: 10 {t('minutes')}</p>
                            </div>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  

                  {/* Course Detail Card */}
                  {allTrainingPrograms && allTrainingPrograms.map((trainingProgram, index) => (
                    <div
                    key={index}
                    className={`col-lg-4 col-md-6 col-sm-12 d-flex`} 
                  >
                    <div
                      className={`course-box flex-fill ${
                        isTrainingProgramDisabled(trainingProgram, index) ? 'disabled-card' : ''
                      }`}
                      
                    >
                            {/* Premium Content Overlay */}
                            {trainingProgram?.product_ids && trainingProgram?.product_ids?.length > 0 ? (
                              trainingProgram?.is_subscribed ? null : (
                                <div className="premium-overlay">
                                  <div className="overlay-content">
                                    <p>Premium Content</p>
                                    <button
                                      className="btn btn-warning"
                                      onClick={() => handlePayToUnlock(trainingProgram)}
                                      style={{zIndex:"100"}}
                                    >
                                      Unlock Now
                                    </button>
                                  </div>
                                </div>
                              )
                            ) : null}


                      {/* Circular Progress Overlay */}
                      <div className="progress-overlay">
                        <div className="progress-circle">
                          <i className="fa-regular fa-square-check fa-beat"></i>
                          &nbsp;{getPercentCompletion(trainingProgram?.srno)}% 
                        </div>
                      </div>
                  
                      <div className="product">
                        <div className="product-img">
                          <Link
                            to="/course-details"
                            state={{
                              trainingProgram,
                              percentCompletion: getPercentCompletion(trainingProgram?.srno),
                            }}
                          >
                            <img
                              className="img-fluid"
                              alt="Img"
                              src={trainingProgram?.banner_image[0]}
                            />
                          </Link>
                        </div>
                        <div className="product-content">
                          <h3 className="title instructor-text" >
                            <Link
                              to="/course-details"
                              state={{
                                trainingProgram,
                                percentCompletion: getPercentCompletion(trainingProgram?.srno),
                              }}
                              className="link-class"
                            >
                              {trainingProgram?.program_header}
                            </Link>
                          </h3>
                          {/* <p className="summary">{trainingProgram?.details}</p>
                          <div className="course-info d-flex align-items-center">
                            <div className="rating-img d-flex align-items-center">
                              <img src={Icon1} alt="Img" />
                              <p>{trainingProgram?.number_of_lessons} Lessons</p>
                            </div>
                            <div className="course-view d-flex align-items-center">
                              <img src={Icon2} alt="Img" />
                              <p>{trainingProgram?.duration}</p>
                            </div>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
  
                  ))}

                 
					
                  {/* Completion Quiz Card */}
                  {/* <div className={`col-lg-4 col-md-6 col-sm-12 d-flex`}>
                      <div className={`course-box flex-fill disabled-card ${quizSubmission?.onboarding_quiz === 'no' ?  'disabled-card' : ''}`}>
                        <div className="product">
                          <div className="product-img">
                            <Link	onClick={(e) => {
                              e.preventDefault();  // Prevent default link behavior
                              endQuiz();
                            }}>
                              <img
                                className="img-fluid"
                                alt="Img"
                                src={quiz02}
                              />
                            </Link>
                            
                            <div className="price">
                              <h3>{t('completionQuiz')}</h3>
                            </div>
                          </div>
                          <div className="product-content">
                            <h3 className="title instructor-text">
                              <Link onClick={(e) => {
                                  e.preventDefault();  // Prevent default link behavior
                                  endQuiz();
                                }}>{t('completionQuiz')}</Link>
                            </h3>
                          </div>
                        </div>
                      </div>
                  </div> */}
  </>
)}

                  
                  
                  {/* Course Grid */}
                  {/* <div className="col-xxl-4 col-md-6 d-flex">
                                        <div className="course-box flex-fill">
                                            <div className="product">
                                                <div className="product-img">
                                                    <Link to="/course-details">
                                                        <img
                                                            className="img-fluid"
                                                            alt="Img"
                                                            src={course04}
                                                        />
                                                    </Link>
                                                    <div className="price">
                                                        <h3>
                                                            $65 <span>$70.00</span>
                                                        </h3>
                                                    </div>
                                                </div>
                                                <div className="product-content">
                                                    <div className="course-group d-flex">
                                                        <div className="course-group-img d-flex">
                                                            <Link to="/instructor/instructor-profile">
                                                                <img
                                                                    src={User4}
                                                                    alt="Img"
                                                                    className="img-fluid"
                                                                />
                                                            </Link>
                                                            <div className="course-name">
                                                                <h4>
                                                                    <Link to="/instructor/instructor-profile">Nicole Brown</Link>
                                                                </h4>
                                                                <p>Instructor</p>
                                                            </div>
                                                        </div>
                                                        <div className="course-share d-flex align-items-center justify-content-center">
                                                        <Link to="#" onClick={() => toggleClass(3)}>
                                                        <i className={`fa-regular fa-heart ${isClassAdded[3] ? 'color-active' : ''}`} />
                                                      </Link>
                                                        </div>
                                                    </div>
                                                    <h3 className="title instructor-text">
                                                        <Link to="/course-details">
                                                            Learn Angular Fundamentals From beginning to advance
                                                            lavel
                                                        </Link>
                                                    </h3>
                                                    <div className="course-info d-flex align-items-center">
                                                        <div className="rating-img d-flex align-items-center">
                                                            <img src={Icon1} alt="Img" />
                                                            <p>15+ Lesson</p>
                                                        </div>
                                                        <div className="course-view d-flex align-items-center">
                                                            <img src={Icon2} alt="Img" />
                                                            <p>80hr 40min</p>
                                                        </div>
                                                    </div>
                                                    <div className="rating mb-0">
                                                        <i className="fas fa-star filled me-1" />
                                                        <i className="fas fa-star filled me-1" />
                                                        <i className="fas fa-star filled me-1" />
                                                        <i className="fas fa-star filled me-1" />
                                                        <i className="fas fa-star me-1" />
                                                        <span className="d-inline-block average-rating">
                                                            <span>4.0</span> (10)
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                  {/* /Course Grid */}
                  {/* Course Grid */}
                  {/* <div className="col-xxl-4 col-md-6 d-flex">
                                        <div className="course-box flex-fill">
                                            <div className="product">
                                                <div className="product-img">
                                                    <Link to="/course-details">
                                                        <img
                                                            className="img-fluid"
                                                            alt="Img"
                                                            src={course05}
                                                        />
                                                    </Link>
                                                    <div className="price combo">
                                                        <h3>FREE</h3>
                                                    </div>
                                                </div>
                                                <div className="product-content">
                                                    <div className="course-group d-flex">
                                                        <div className="course-group-img d-flex">
                                                            <Link to="/instructor/instructor-profile">
                                                                <img
                                                                    src={User3}
                                                                    alt="Img"
                                                                    className="img-fluid"
                                                                />
                                                            </Link>
                                                            <div className="course-name">
                                                                <h4>
                                                                    <Link to="/instructor/instructor-profile">John Smith</Link>
                                                                </h4>
                                                                <p>Instructor</p>
                                                            </div>
                                                        </div>
                                                        <div className="course-share d-flex align-items-center justify-content-center">
                                                        <Link to="#" onClick={() => toggleClass(4)}>
                                                        <i className={`fa-regular fa-heart ${isClassAdded[4] ? 'color-active' : ''}`} />
                                                      </Link>
                                                        </div>
                                                    </div>
                                                    <h3 className="title instructor-text">
                                                        <Link to="/course-details">
                                                            Build Responsive Real World Websites with Crash Course
                                                        </Link>
                                                    </h3>
                                                    <div className="course-info d-flex align-items-center">
                                                        <div className="rating-img d-flex align-items-center">
                                                            <img src={Icon1} alt="Img" />
                                                            <p>12+ Lesson</p>
                                                        </div>
                                                        <div className="course-view d-flex align-items-center">
                                                            <img src={Icon2} alt="Img" />
                                                            <p>70hr 30min</p>
                                                        </div>
                                                    </div>
                                                    <div className="rating mb-0">
                                                        <i className="fas fa-star filled me-1" />
                                                        <i className="fas fa-star filled me-1" />
                                                        <i className="fas fa-star filled me-1" />
                                                        <i className="fas fa-star filled me-1" />
                                                        <i className="fas fa-star me-1" />
                                                        <span className="d-inline-block average-rating">
                                                            <span>4.0</span> (15)
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                  {/* /Course Grid */}
                  {/* Course Grid */}
                  {/* <div className="col-xxl-4 col-md-6 d-flex">
                                        <div className="course-box flex-fill">
                                            <div className="product">
                                                <div className="product-img">
                                                    <Link to="/course-details">
                                                        <img
                                                            className="img-fluid"
                                                            alt="Img"
                                                            src={course07}
                                                        />
                                                    </Link>
                                                    <div className="price">
                                                        <h3>
                                                            $70 <span>$80.00</span>
                                                        </h3>
                                                    </div>
                                                </div>
                                                <div className="product-content">
                                                    <div className="course-group d-flex">
                                                        <div className="course-group-img d-flex">
                                                            <Link to="/instructor/instructor-profile">
                                                                <img
                                                                    src={User6}
                                                                    alt="Img"
                                                                    className="img-fluid"
                                                                />
                                                            </Link>
                                                            <div className="course-name">
                                                                <h4>
                                                                    <Link to="/instructor/instructor-profile">
                                                                        Stella Johnson
                                                                    </Link>
                                                                </h4>
                                                                <p>Instructor</p>
                                                            </div>
                                                        </div>
                                                        <div className="course-share d-flex align-items-center justify-content-center">
                                                        <Link to="#" onClick={() => toggleClass(5)}>
                                                        <i className={`fa-regular fa-heart ${isClassAdded[5] ? 'color-active' : ''}`} />
                                                      </Link>
                                                        </div>
                                                    </div>
                                                    <h3 className="title instructor-text">
                                                        <Link to="/course-details">
                                                            Learn JavaScript and Express to become a Expert
                                                        </Link>
                                                    </h3>
                                                    <div className="course-info d-flex align-items-center">
                                                        <div className="rating-img d-flex align-items-center">
                                                            <img src={Icon1} alt="Img" />
                                                            <p>15+ Lesson</p>
                                                        </div>
                                                        <div className="course-view d-flex align-items-center">
                                                            <img src={Icon2} alt="Img" />
                                                            <p>70hr 30min</p>
                                                        </div>
                                                    </div>
                                                    <div className="rating mb-0">
                                                        <i className="fas fa-star filled me-1" />
                                                        <i className="fas fa-star filled me-1" />
                                                        <i className="fas fa-star filled me-1" />
                                                        <i className="fas fa-star filled me-1" />
                                                        <i className="fas fa-star me-1" />
                                                        <span className="d-inline-block average-rating">
                                                            <span>4.6</span> (15)
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                  {/* /Course Grid */}
                  {/* Course Grid */}
                  {/* <div className="col-xxl-4 col-md-6 d-flex">
                                        <div className="course-box flex-fill">
                                            <div className="product">
                                                <div className="product-img">
                                                    <Link to="/course-details">
                                                        <img
                                                            className="img-fluid"
                                                            alt="Img"
                                                            src={course08}
                                                        />
                                                    </Link>
                                                    <div className="price combo">
                                                        <h3>FREE</h3>
                                                    </div>
                                                </div>
                                                <div className="product-content">
                                                    <div className="course-group d-flex">
                                                        <div className="course-group-img d-flex">
                                                            <Link to="/instructor/instructor-profile">
                                                                <img
                                                                    src={User1}
                                                                    alt="Img"
                                                                    className="img-fluid"
                                                                />
                                                            </Link>
                                                            <div className="course-name">
                                                                <h4>
                                                                    <Link to="/instructor/instructor-profile">Nicole Brown</Link>
                                                                </h4>
                                                                <p>Instructor</p>
                                                            </div>
                                                        </div>
                                                        <div className="course-share d-flex align-items-center justify-content-center">
                                                        <Link to="#" onClick={() => toggleClass(6)}>
                                                        <i className={`fa-regular fa-heart ${isClassAdded[6] ? 'color-active' : ''}`} />
                                                      </Link>
                                                        </div>
                                                    </div>
                                                    <h3 className="title instructor-text">
                                                        <Link to="/course-details">
                                                            Introduction to Programming- Python &amp; Java
                                                        </Link>
                                                    </h3>
                                                    <div className="course-info d-flex align-items-center">
                                                        <div className="rating-img d-flex align-items-center">
                                                            <img src={Icon1} alt="Img" />
                                                            <p>10+ Lesson</p>
                                                        </div>
                                                        <div className="course-view d-flex align-items-center">
                                                            <img src={Icon2} alt="Img" />
                                                            <p>70hr 30min</p>
                                                        </div>
                                                    </div>
                                                    <div className="rating mb-0">
                                                        <i className="fas fa-star filled me-1" />
                                                        <i className="fas fa-star filled me-1" />
                                                        <i className="fas fa-star filled me-1" />
                                                        <i className="fas fa-star filled me-1" />
                                                        <i className="fas fa-star filled me-1" />
                                                        <span className="d-inline-block average-rating">
                                                            <span>5.0</span> (13)
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                  {/* /Course Grid */}
                </div>
                {/* <div className="dash-pagination">
                  <div className="row align-items-center">
                    <div className="col-6">
                      <p>Page 1 of 2</p>
                    </div>
                    <div className="col-6">
                      <ul className="pagination">
                        <li className="active">
                          <Link to="#">1</Link>
                        </li>
                        <li>
                          <Link to="#">2</Link>
                        </li>
                        <li>
                          <Link to="#">
                            <i className="bx bx-chevron-right" />
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div> */}
              </div>
              {/* Student Dashboard */}
            </div>
          </div>
        </div>
        {/* /Page Content */}
        {/* Footer */}
        {/* /Footer */}
      </div>
      {/* /Main Wrapper */}
    </>
  );
};

export default StudentDashboard;



