import React,{useState} from "react";
import PropTypes from "prop-types";
import "./tool.css";
import {CalculatePMOSToolkit3} from "../../../../../api/programApi";
import {formatWithDollarAndCommas,stripFormatting,formatNumberWithCommasAndMillions } from "../../../../../utils/helper";

const PMOSToolkit3 = ({data}) => {
   
    const [years, setYears] = useState("0");
    const [dailyCost, setDailyCost] = useState("0");
    const [investedOrSpent, setInvestedOrSpent] = useState("0");
    const [calculatedValue, setCalculatedValue] = useState(null);

    const handleInvestedOrSpentChange = (e) => {
        setInvestedOrSpent(e.target.value); 
      };
    const handleDailyCostChange = (e) => {
        setDailyCost(e.target.value); 
      };

    const handleBlur = async () => {
        try {
            setInvestedOrSpent((prev) => formatWithDollarAndCommas(prev));
            setDailyCost((prev) => formatWithDollarAndCommas(prev));
          
          const response = await CalculatePMOSToolkit3(stripFormatting(investedOrSpent),stripFormatting(dailyCost),years);
    
          setCalculatedValue({
            future_value:response?.future_value,
            monthly_cost_value:response?.monthly_cost_value,
            annual_cost_value:response?.annual_cost_value,
            });
    
 
            
        } catch (error) {
          console.error("Error calculating PMOSToolkit3 value", error);
        }
      };

    const getField = (fieldName) => {
        const field = data?.data?.find((item) => item.field_name === fieldName);
        if (field && field.toolkit_label_status === "Inactive") {
          return '';
        }
        return field ? field.field_value : "";
      };

  return (
    <div className="share-calculator">
      {/* Header */}
      <h3 className="background-blue font-white tool-header">
        <i className="fas fa-calculator"></i> {getField("lesson_header")}
      </h3>

      {/* Rows */}
      <div className="tool-row">
        <div className="tool-column tool-label">{getField("invested_spent")}</div>
        <div className="tool-column">
          <input
            type="text"
            className="form-input"
            value={investedOrSpent}
            onChange={handleInvestedOrSpentChange}
            onBlur={handleBlur}
          />
        </div>
      </div>
      <div className="tool-row">
        <div className="tool-column tool-label">{getField("interest_rate")}</div>
        <div className="tool-column">{getField("interest_rate_value")}%</div>
      </div>
      <div className="tool-row">
        <div className="tool-column tool-label">{getField("years")}</div>
        <div className="tool-column">
          <input
            type="text"
            className="form-input"
            value={years}
            onChange={(e) => setYears(e.target.value)}
            onBlur={handleBlur}
          />
        </div>
      </div>
      <div className="tool-row">
        <div className="tool-column tool-label">{getField("future")}</div>
        <div className="tool-column exchange-rate-result">{calculatedValue?.future_value !== undefined ? `$${formatNumberWithCommasAndMillions(calculatedValue?.future_value)}` : ""}</div>
      </div>

      {/* Section Header */}
      <h3 className="background-blue font-white tool-header">{getField("impact")}</h3>

      <div className="tool-row">
        <div className="tool-column tool-label">{getField("daily_cost")}</div>
        <div className="tool-column">
          <input
            type="text"
            className="form-input"
            value={dailyCost}
            onChange={handleDailyCostChange}
            onBlur={handleBlur}
          />
        </div>
      </div>
      <div className="tool-row">
        <div className="tool-column tool-label">{getField("monthly_cost")}</div>
      <div className="tool-column exchange-rate-result">{calculatedValue?.monthly_cost_value !== undefined ? `$${formatNumberWithCommasAndMillions(calculatedValue?.monthly_cost_value)}` : ""}</div>
      </div>
      <div className="tool-row">
        <div className="tool-column tool-label">{getField("annual_cost")}</div>
        <div className="tool-column exchange-rate-result">{calculatedValue?.annual_cost_value !== undefined ? `$${formatNumberWithCommasAndMillions(calculatedValue?.annual_cost_value)}` : ""}</div>
      </div>
    </div>
  );
};

PMOSToolkit3.propTypes = {
    data: PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape({
          field_name: PropTypes.string.isRequired,
          field_value: PropTypes.string.isRequired,
        })
      ).isRequired,
    }).isRequired,
  };

export default PMOSToolkit3;