import React, { useState, useEffect } from "react";
import Header from "../header";
import Toolbox from "./Toolbox";
import PersonalMoneyTools from "./PersonalMoneyTools";
import ForecasterToolbox from "./ForecasterToolbox";
import { getToolkitData,getStudentConfigData,updateStudentForecastConfigData } from "../../../api/programApi";
import StudentSidebar from "../sidebar";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';

const ToolkitBox = () => {
  const [activeTab, setActiveTab] = useState(null); 
  const [toolkitData1, setToolkitData1] = useState(null); // Data for PMOSToolkit1
  const [toolkitData2, setToolkitData2] = useState(null); // Data for PMOSToolkit2
  const [toolkitData, setToolkitData] = useState(null);
  const [toolkitsValue, setToolkitsValue] = useState([]);



  const userEmail = localStorage.getItem("Email");

   const navigate = useNavigate();

    const handleDashboardRedirect = () => {
      navigate("/student/student-dashboard");
      setTimeout(() => {
        navigate("/student/student-toolbox"); 
      }, 100); 
    };

    const handleDeleteToolkit = async (companySymbol) => {
     
        try {
          await updateStudentForecastConfigData(userEmail, companySymbol); 
          setToolkitsValue((prevToolkits) =>
            prevToolkits.filter((toolkit) => toolkit.companySymbol !== companySymbol))
          toast.success("Toolkit removed successfully!");
        } catch (error) {
          console.error("Error removing toolkit:", error);
          toast.error("Failed to remove toolkit.");
        }
     };


  

    useEffect(() => {
      const language = localStorage.getItem("userLanguage");

      if (activeTab === "PersonalMoneyTools") {
        // Fetch data for both PMOSToolkit1 and PMOSToolkit2
        getToolkitData("PMOSToolkit1", language)
          .then((data) => {
            setToolkitData1(data)
          })
          .catch((error) => {
            console.error("Error fetching PMOSToolkit1 data:", error);
            setToolkitData1(null);
          });

        getToolkitData("PMOSToolkit2", language)
          .then((data) => {
            setToolkitData2(data)}
          )
          .catch((error) => {
            console.error("Error fetching PMOSToolkit2 data:", error);
            setToolkitData2(null);
          });
      } else if (activeTab) {
        // Fetch data for other toolkits
        getToolkitData(activeTab, language)
          .then((data) => setToolkitData(data))
          .catch((error) => {
            console.error("Error fetching toolkit data:", error);
            setToolkitData(null);
          });
      }
    }, [activeTab]);

    const handleSelectToolbox = (toolbox) => {

      if (toolbox === "PMOSToolkit1") {
        setActiveTab("PersonalMoneyTools"); 
      } else {
        setActiveTab(toolbox);
      }
    };


    const renderActiveTab = () => {
      if (!activeTab) {
        return <Toolbox onSelectToolbox={handleSelectToolbox} />;
      }
      switch (activeTab) {
        case "PersonalMoneyTools":
          return <PersonalMoneyTools toolkitData1={toolkitData1} toolkitData2={toolkitData2} />;
        case "Program3Toolkit5":
          return <ForecasterToolbox toolkitData={toolkitData} toolkits={toolkitsValue} onDeleteToolkit={handleDeleteToolkit}
          onAddToolkit={handleAddToolkit} fetchToolkitData={fetchToolkitData}  />;
        default:
          return null;
      }
    };

    const handleAddToolkit = (companySymbol) => {
      const newToolkit = { id: Date.now(), companySymbol,calculatedData: {growth_rate_quarter_27:"0",percent_sales_quarter_27:"0"} };
      setToolkitsValue((prevData) => [...prevData, newToolkit]);
    }
    

    const fetchToolkitData = async () => {
      try {
        const response = await getStudentConfigData(userEmail);
        const toolkitData = response; 
        const updatedToolkits = toolkitData.map((toolkit) => ({
          id: toolkit.srno,
          companySymbol: toolkit.symbol,
          calculatedData: toolkit.data, 
        }));
        setToolkitsValue(updatedToolkits);
      } catch (error) {
        console.error("Error fetching toolkit data:", error);
      }
    };

    useEffect(() => {
      fetchToolkitData();
    }, [userEmail]);

   
    
  return (
    <div className="main-wrapper">
      <Header />
      <div className="page-content bg-light-grayish-blue">
        <div className={`${activeTab !== null ? "container-fluid" : "container"}`}>
          <div className="row">
            {/* <StudentSidebar /> */}
            {activeTab !== "PersonalMoneyTools" && activeTab !== "Program3Toolkit5" && <StudentSidebar />}
            <div className={`${activeTab === "PersonalMoneyTools" || activeTab === "Program3Toolkit5" ? "col-12" : "col-xl-9 col-lg-9 container-with-margin"}`} style={{ marginTop: "50px" }}>{/* Breadcrumb */}
                        <div className="breadcrumb-bar breadcrumb-bar-info">
                          <div className="container">
                            <div className="row">
                              <div className="col-md-12 col-12">
                                <div className="breadcrumb-list">
                                    <nav aria-label="breadcrumb" className="page-breadcrumb">
                                      <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                        <span
                                            onClick={handleDashboardRedirect}
                                            style={{ cursor: "pointer" }}
                                          >
                                            Dashboard
                                          </span>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                        {activeTab === "PersonalMoneyTools" ? "Personal Money System" : "Toolbox"}
                                        </li>
                                      </ol>
                                    </nav>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                  {/* /Breadcrumb */} 

                {/* Render Active Tab */}
                <div className="mt-3">{renderActiveTab()}</div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default ToolkitBox;
