import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import "../../../assets/css/quiz.css";
import {
  fetchQuestionById,
  getQuizByType,
  saveOnBoardingQuiz,
  
} from "../../../api/studentApi";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {trackEvent} from "../../../utils/trackEvent";

const Quiz = ({quizType}) => {

  const { t } = useTranslation();
  const [questionData, setQuestionData] = useState(null);
  const [error, setError] = useState(null); 
  const [quizName, setQuizName] = useState("");
  const [quizDescription, setQuizDescription] = useState("");
  const [QuizCompletionMessage, setQuizCompletionMessage] = useState("");
 
  const language = localStorage.getItem('userLanguage'); 
  const userEmail = localStorage.getItem('Email')
 
  const [quizQuestions, setQuizQuestions] = useState([]); // Array of question IDs
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(0); // Index of current question
  const [selectedAnswers, setSelectedAnswers] = useState([]); // Stores all answers
  const [selectedAnswerIndex, setSelectedAnswerIndex] = useState([]); // Stores answer indexes for current question
  const [showResult, setShowResult] = useState(false); 
  // eslint-disable-next-line no-unused-vars
  const [result, setResult] = useState(null); // Store result
  // const [elapsedTime, setElapsedTime] = useState(0); 

  const [quizStartTime, setQuizStartTime] = useState(null); 
  const [quizEndTime, setQuizEndTime] = useState(null); 
  const [questionStartTime, setQuestionStartTime] = useState(null); // For each question start time
  const [textInput, setTextInput] = useState(""); 

  const [showStartPage, setShowStartPage] = useState(true);

  const handleStartQuiz = () => {
    setShowStartPage(false); 
  };
  
  const handlePrevious = async () => {
    if (activeQuestionIndex > 0) {
      const previousQuestionIndex = activeQuestionIndex - 1;
  
      // Load the previous question
      await loadQuestion(quizQuestions[previousQuestionIndex]);
  
      // Retrieve the previous answer, if it exists
      const previousAnswer = selectedAnswers[previousQuestionIndex];
      if (previousAnswer) {
        const optionType = questionData?.options?.[0]?.option_type;
  
        if (optionType === "Freetext") {
          // Set text input for Freetext questions
          setTextInput(previousAnswer.answers || "");
          setSelectedAnswerIndex([]); // Clear selectedAnswerIndex for Freetext
        } else if (optionType === "Normal" || optionType === "Image") {
          // Set selectedAnswerIndex for Normal or Image questions
          const previousIndices = Array.isArray(previousAnswer.answers)
            ? previousAnswer.answers.map((answer) =>
                questionData.options.findIndex((opt) => opt.option_desc === answer)
              )
            : [];
          setSelectedAnswerIndex(previousIndices);
          setTextInput(""); 
        }
      } else {
        // Reset if there's no previous answer
        setSelectedAnswerIndex([]);
        setTextInput("");
      }
  
      setActiveQuestionIndex(previousQuestionIndex);
    }
  };
  
  
  
  
  
  const handleTextInputChange = (e) => {
    setTextInput(e.target.value); 
  };
  

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setElapsedTime((prevTime) => prevTime + 1); 
  //   }, 1000);
  
  //   return () => clearInterval(interval); 
  // }, []);


  useEffect(() => {
    const fetchQuizIds = async () => {
      try {

        if (language && userEmail) {
       
          const quizData = await getQuizByType(quizType, language, userEmail);
         
          if (quizData && quizData.data && quizData.data.length > 0) {
            setQuizName(quizData.data[0].quiz_name);
            setQuizDescription(quizData.data[0].quiz_description);
            setQuizCompletionMessage(quizData.data[0].quiz_completion_message);
            setQuizQuestions(quizData.data[0].questions); 
            setQuizStartTime(new Date());
            // Load the first question
            await loadQuestion(quizData.data[0].questions[0]);
          } else {
            console.error('No quiz data found');
          }
        } else {
          console.error('Language or Email not found in localStorage');
        }
      } catch (error) {
        console.error('Error fetching quiz:', error);
      }
    };
  
    fetchQuizIds();
  }, [quizType]);
  
  

  // const formatTime = (timeInSeconds) => {
  //   const minutes = Math.floor(timeInSeconds / 60);
  //   const seconds = timeInSeconds % 60;
  //   return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  // };

const loadQuestion = async (questionId) => {
  const data = await fetchQuestionById(questionId);
  setQuestionData(data); // Set current question data
  setQuestionStartTime(new Date()); // Record question start time
};

  useEffect(() => {
    if (showResult) {
      submitOnBoardingQuiz(); // Submit quiz results when quiz ends
    }
  }, [showResult]);


  const onAnswerSelected = (answerIndex) => {

    const maxCount = questionData.max_count || null;

    if (questionData.type === "Any Answer") {
      return;
    } else if (questionData.type === "Single Answer") {

      if(selectedAnswerIndex.includes(answerIndex)){
        setSelectedAnswerIndex([]);
      }else{
        setSelectedAnswerIndex([answerIndex]); // Only one answer for Single Answer
      }
    } else if (questionData.type === "Multiple Answers") {

      if (maxCount && selectedAnswerIndex.length >= maxCount) {
        return; // Prevent adding more options beyond max_count
      }

      if(selectedAnswerIndex.includes(answerIndex)){
        setSelectedAnswerIndex((prev) => {
          return prev.includes(answerIndex)
            ? prev.filter((i) => i !== answerIndex)
            : [...prev];
        });
      }else{
        setSelectedAnswerIndex((prev) => {
          return prev.includes(answerIndex)
            ? prev.filter((i) => i !== answerIndex)
            : [...prev, answerIndex];
        });
      }
    } else if (questionData.type === "Chronological Ordering") {
      // Same logic for both types: selected answer is added to sequence or list
      if(selectedAnswerIndex.includes(answerIndex)){
        setSelectedAnswerIndex((prev) => {
          return !prev.includes(answerIndex) ? [...prev] : prev.filter((i) => i !== answerIndex);
        });
      }else{
        setSelectedAnswerIndex((prev) => {
          return !prev.includes(answerIndex) ? [...prev, answerIndex] : prev;
        });
      }
    }
  };

  
    const calculateQuizCompletionTime = () => {
      if (quizStartTime && quizEndTime) {
        return Math.floor((quizEndTime - quizStartTime) / 1000); // Time in seconds
      }
      return 0;
    };
    
  
  const submitOnBoardingQuiz = async () => {
    // Calculate total quiz completion time
    const quizCompletionTime = calculateQuizCompletionTime();
  
    // Fetch user details from local storage
    const studentName = localStorage.getItem('Name');
    const studentEmail = localStorage.getItem('Email');
    const studentId = localStorage.getItem('UserId');
    const userLanguage = localStorage.getItem('userLanguage');
  
    const quizResults = selectedAnswers.map((answer, index) => ({
      srno: 0,
      question_id: quizQuestions[index],
      quiz_type: quizType,
      question_name: `Question ${index + 1}`,
      question_type: questionData.type,
      expected_answers: "",
      actual_answers:  Array.isArray(answer.answers)
      ? answer.answers.join(", ")
      : answer.answers,
      // opinion_answer: answer.text, 
      user_id: studentId,
      user_email: studentEmail,
      user_name: studentName,
      start_time: answer.start_time, 
      end_time: answer.end_time,      
      time_taken: answer.time_taken,  
    }));
 

    const quizSubmission = {
      srno: 0,
      quiz_id: 1,
      quiz_name: quizName,
      quiz_type: quizType,
      start_time: quizStartTime, 
      end_time: quizEndTime,     
      quiz_completion_time: quizCompletionTime, 
      user_id: studentId,
      user_email: studentEmail,
      user_name: studentName,
      language:userLanguage,
    };
 
    try {
      const data = await saveOnBoardingQuiz(quizResults, quizSubmission);
      if (data.success) {
        setResult(data.data);
        setError(null);

        // Track the onboarding quiz completion event
        trackEvent('Onboarding Quiz', 'Quiz Completed');
      } else {
        console.error("Failed to save quiz results:", data.message);
        setError("Failed to save quiz results:"+ data.message);
      }
    } catch (error) {
      console.error("Error saving quiz results:", error);
      setError("Error saving quiz result:"+ error);
    }
  };
  


  const onClickNext = async () => {
    if (selectedAnswerIndex.length === 0 && textInput === "") {
      setError(t("errorSelectOption"));
      return;
    }
    setError(null);

    setSelectedAnswers((prevAnswers) => {
      const updatedAnswers = [...prevAnswers];
    
      // Calculate time taken for the current question
      const timeTakenForQuestion = (new Date() - questionStartTime) / 1000;

      const optionType = questionData?.options?.[0]?.option_type;
      // Create the currentAnswer object based on optionType
      const currentAnswer = {
        answers: optionType === "Freetext" ? textInput :
                 optionType === "Normal" || optionType === "Image"
                  ? selectedAnswerIndex.map(index => questionData.options[index]?.option_desc) :
                null, // Handle undefined or unknown option_type
                 
        start_time: questionStartTime.toISOString(),
        end_time: new Date().toISOString(),
        time_taken: timeTakenForQuestion,
      };
    
      // Add or update the current answer for the active question
      updatedAnswers[activeQuestionIndex] = currentAnswer;
      return updatedAnswers;
    });
    
  
    setSelectedAnswerIndex([]); 
    setTextInput(""); 
  
    if (activeQuestionIndex < quizQuestions.length - 1) {
      await loadQuestion(quizQuestions[activeQuestionIndex + 1]); // Load next question
      setActiveQuestionIndex(activeQuestionIndex + 1);
    } else {
      setShowResult(true); 
      setQuizEndTime(new Date()); // Record quiz end time
    }
  };
 
  
  
  if (!questionData) {
    return <div style={{maringTop: '100px'}}>{t('loading')}...</div>;
  }

  const { question, options, reference_image, reference_video,type } =
    questionData;
  
  const addLeadingZero = (number) => (number > 9 ? number : `0${number}`)


  return (
    <div className="quiz-container-student">
      {showStartPage ? (
        <div className="start-page">
          {/* Quiz Description */}
          <h1>{quizName}</h1>
          <div dangerouslySetInnerHTML={{ __html: quizDescription}}></div>

          {/* Start Button */}
          <button className="btn btn-primary mt-3 bg-ivot-primary" onClick={handleStartQuiz}>
            {t('startQuiz')} {/* Using i18n for translation */}
          </button>
        </div>
      ) : (
        !showResult && !showStartPage && (
          <>
            <div className="quiz-header">
              <h1>{quizName}</h1>
              <div className="quiz-info">
                <div>
                  <span className="active-question-no">{addLeadingZero(activeQuestionIndex + 1)}</span>
                  <span className="total-question">/{addLeadingZero(quizQuestions.length)}</span>
                </div>
                {/* <div className="flex-center">
                  <i className="fas fa-clock timer-icon"></i>
                  <span className="timer-text">{formatTime(elapsedTime)}</span>
                </div> */}
  
              </div>
              
              {questionData.context &&  <h2 dangerouslySetInnerHTML={{ __html: questionData.context }}></h2>}
              <h2 dangerouslySetInnerHTML={{ __html: question }}></h2>
              
            </div>
    
            <div className="quiz-body">
             
              <div className="quiz-options">
                <ul>
                  {options?.map((option, index) => (
                    <li
                      key={index}
                      onClick={() => onAnswerSelected(index)}
                      className={selectedAnswerIndex.includes(index) ? "selected-answer" : ""}
                    >
                      {option.option_type === "Normal" && option.option_desc}
                      {option.option_type === "Image" && (
                        <img
                          src={option.image?.[0]?.url}
                          alt="Option"
                          className="img-fluid"
                          style={{
                            maxHeight: "300px",
                            maxWidth: "100%",
                            objectFit: "contain",
                          }}
                        />
                      )}
                      {option.option_type === "Freetext" && (
                        <input
                          className="form-control border-0 w-100"
                          type="text"
                          placeholder={option.option_desc}
                          value={textInput}
                          onChange={handleTextInputChange}
                        />
                      )}
                    </li>
                  ))}
                </ul>
                
              </div>
  
    
              <div className="quiz-reference">
                {reference_image?.length > 0 && (
                  <img
                    src={reference_image[0]}
                    className="img-fluid"
                    style={{
                      maxHeight: "300px",
                      maxWidth: "100%",
                      objectFit: "contain",
                    }}
                    alt="Reference"
                  />
                )}
                {reference_video && <video src={reference_video} controls />}
              </div>
    
              {error && (
                <div className="my-2 px-2" style={{borderLeft: '3px solid red'}}>
                  <label style={{color: 'red'}}>{t('errorOccurred')}</label>
                  <pre>{JSON.stringify(error, null, 2)}</pre>
                </div>
              )}
  
              <div className="flex-between">
  
                <button
                  onClick={handlePrevious}
                  disabled={activeQuestionIndex === 0}
                >
                  {t('previous')}
                </button>
  
                <button
                onClick={onClickNext}
                disabled={
                (type === "Single Answer" && selectedAnswerIndex.length === 0) ||
                (type === "Multiple Answers" && selectedAnswerIndex.length === 0) ||
                (type === "Chronological Ordering" && selectedAnswerIndex.length !== options.length)
              }
                >
                {activeQuestionIndex === quizQuestions.length - 1 ? t('finish') : t('next')}
                </button>
                
  
              </div>
            </div>
          </>
        )
      )}

      {showResult && (
        <div className="result">
          <div dangerouslySetInnerHTML={{ __html: QuizCompletionMessage}}></div>
          {/* <h3>{t('thankyouQuizSubmitted')}</h3>
          <p>{t('submitToInstructor')}</p>
          <p>{t('startTrainingProgram')}</p> */}
          <button>
            <Link
              className="nav-link header-login"
              to="/student/student-dashboard"
              state={{ refreshQuizSubmission: true }} // Pass a state to signal refresh
            >
              {t('gotoDashboard')}
            </Link>
          </button>
        </div>
      )}
    </div>
  );
};

Quiz.propTypes = {
  quizType: PropTypes.string.isRequired, // Add this prop type validation
};

export default Quiz;



