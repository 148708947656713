import React, { useState ,useEffect} from "react";
// import StickyBox from "react-sticky-box";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { updateStudentDetails, getStudentDetails} from "../../../api/studentApi";
// import useLogout from "../../../hooks/useLogout";
import { useSelector, useDispatch } from "react-redux";
import { setUserAvatar } from "../../../components/common/redux/userSlice";
import { useTranslation } from "react-i18next";
import { User16 } from "../../imagepath";

export default function StudentSidebar() {
  const { t } = useTranslation();
  // const { handleLogoutAndRedirect } = useLogout();
  const location = useLocation();

  // Get user details from Redux
  const userName = useSelector((state) => state.userSlice.name);
  const avatar = useSelector((state) => state.userSlice.avatar);
  const email = useSelector((state) => state.userSlice.email);

  const [uploading, setUploading] = useState(false);
  const [toolkitDisabled, setToolkitDisabled] = useState(null);

  const dispatch = useDispatch();

  const handleProfilePictureClick = () => {
    document.getElementById("avatarInput").click();
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      setUploading(true);
      reader.onloadend = async () => {
        const base64Image = reader.result;
        const fileName = file.name;

        if (email) {
          const updateData = {
            email,
            avatar: {
              files: [base64Image],
              fileNames: [fileName],
            },
            status: "Active",
          };

          try {
            await updateStudentDetails(updateData);
            const studentDetailsResponse = await getStudentDetails(email);
            const updatedAvatar = studentDetailsResponse?.data?.data[0]?.avatar[0] || null;
            dispatch(setUserAvatar(updatedAvatar));
          } catch (error) {
            console.error("Error updating avatar:", error);
          } finally {
            setUploading(false);
          }
        }
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    // Fetch subscription details
    const fetchSubscriptionDetails = async () => {
      if (email) {
        try {
          const studentDetailsResponse = await getStudentDetails(email);
          const subscriptions = studentDetailsResponse?.data?.data[0]?.subscriptions || [];

          const isActiveSubscription = subscriptions.some(
            (sub) => sub.status === "Active"
          );
          setToolkitDisabled(!isActiveSubscription);
        } catch (error) {
          console.error("Error fetching subscription details:", error);
          setToolkitDisabled(true);
        }
      }
    };

    fetchSubscriptionDetails();
  }, [email]);
 
  return (
    <div className="col-xl-3 col-lg-3  only-display-web"
    style={{
      position: "fixed",
      top: "72px", 
      left: "-12px",  
      overflowY: "hidden", 
    }}
    >
        <div
          style={{
            // backgroundColor: "#0C4D8D",
            backgroundColor: "#2c2c2c",
            color: "#8a8989",
            padding: "20px",
            // borderRadius: "8px",
            height: "100vh", 
          }}
        >
          {/* Avatar Section */}
          <div className="text-center mb-4">
            <Link to="#" onClick={handleProfilePictureClick}>
              <img
                src={avatar || User16}
                alt="Profile"
                style={{ width: "100px", height: "100px", borderRadius: "50%" }}
              />
            </Link>
            <input
              type="file"
              id="avatarInput"
              style={{ display: "none" }}
              accept="image/*"
              onChange={handleFileChange}
              disabled={uploading}
            />
            {uploading && <p>{t("uploading")}...</p>}
            <h5 className="mt-3 text-white">{t("welcomeBack")},</h5>
            <h4 className="text-white">{userName}</h4>
          </div>

          {/* Settings Menu */}
          <div className="main-nav">
          <ul className="list-unstyled d-flex flex-column gap-3" style={{padding:"30px"}}>
              <li className={`nav-item ${location.pathname === "/student/student-dashboard" ? "active" : ""}`}>
                <Link
                  to="/student/student-dashboard"
                  className={`nav-link d-flex align-items-center justify-content-start ${location.pathname === "/student/student-dashboard" ? "text-white" : "text-muted"}`}
                >
                  <span style={{ width: "30px", textAlign: "center" }}>
                    <i className="bx bxs-tachometer" />
                  </span>
                  <span>{t("dashboard")}</span>
                </Link>
              </li>
              <li className={`nav-item ${location.pathname === "/student/student-profile" ? "active" : ""}`}>
                <Link
                  to="/student/student-profile"
                  className={`nav-link d-flex align-items-center justify-content-start ${location.pathname === "/student/student-profile" ? "text-white" : "text-muted"}`}
                >
                  <span style={{ width: "30px", textAlign: "center" }}>
                    <i className="bx bxs-user" />
                  </span>
                  <span>{t("myProfile")}</span>
                </Link>
              </li>

              <li className="nav-item">
                <a
                  href="https://community.ivot.academy"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="nav-link d-flex align-items-center justify-content-start text-muted"
                >
                  <span style={{ width: "30px", textAlign: "center" }}>
                    <i className="bx bx-group" />
                  </span>
                  <span>{t("community")}</span>
                </a>
              </li>
              
              {/* <li className={`nav-item ${location.pathname === "/student/student-toolbox"? "active" : ""}`}>
                <Link to="/student/student-toolbox" 
                      className={`nav-link d-flex align-items-center justify-content-start ${location.pathname === "/student/student-toolbox" ? "text-white" : "text-muted"}`}>
                  <span style={{ width: "30px", textAlign: "center" }}>
                  <i className="bx bx-cog" />
                  </span>
                  <span>{t("toolbox")}</span>
                </Link>
              </li> */}
                          <li
              className={`nav-item ${
                location.pathname === "/student/student-toolbox" ? "active" : ""
              } ${toolkitDisabled ? "disabled" : ""}`}
            >
              <Link
                to={toolkitDisabled ? "#" : "/student/student-toolbox"}
                className={`nav-link d-flex align-items-center justify-content-start ${
                  location.pathname === "/student/student-toolbox" ? "text-white" : "text-muted"
                }`}
                style={{ pointerEvents: toolkitDisabled ? "none" : "auto", opacity: toolkitDisabled ? 0.5 : 1 }}
              >
                <span style={{ width: "30px", textAlign: "center" }}>
                  <i className="bx bx-cog" />
                </span>
                <span>{t("toolbox")}
                {toolkitDisabled && (
                      <span
                        className="pro-tag"
                      >
                        PRO
                      </span>
                    )}
                </span>
              </Link>
            </li>

              <li className={`nav-item ${location.pathname === "/student/student-setting"? "active" : ""}`}>
                <Link
                  to="/student/student-setting"
                  className={`nav-link d-flex align-items-center justify-content-start ${location.pathname === "/student/student-setting" ? "text-white" : "text-muted"}`}
                >
                  <span style={{ width: "30px", textAlign: "center" }}>
                    <i className="bx bxs-cog"  />
                  </span>
                  <span>{t('settings')}</span>
                </Link>
              </li>
              
              <li className={`nav-item ${location.pathname === "/student/student-support"? "active" : ""}`}>
                <Link to="/student/student-support" 
                      className={`nav-link d-flex align-items-center justify-content-start ${location.pathname === "/student/student-support" ? "text-white" : "text-muted"}`}>
                  <span style={{ width: "30px", textAlign: "center" }}>
                    <i className="bx bxs-help-circle" />
                  </span>
                  <span>{t("support")}</span>
                </Link>
              </li>
              <li>
                {/* <Link
                  to="/home"
                  className="nav-link d-flex align-items-center justify-content-start text-muted"
                  onClick={(e) => {
                    e.preventDefault();
                    handleLogoutAndRedirect();
                  }}
                >
                  <span style={{ width: "30px", textAlign: "center" }}>
                    <i className="bx bxs-log-out" />
                  </span>
                  <span>{t("logout")}</span>
                </Link> */}
              </li>
            </ul>

          </div>
        </div>
      {/* <StickyBox offsetTop={10} offsetBottom={10}>
      </StickyBox> */}
    </div>
  );
}

