import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { formatNumberWithCommasAndMillions } from "../../../../../utils/helper";
import "./tool.css";

const Program2Toolkit1 = ({ data }) => {
  const userName = useSelector((state) => state.userSlice.name);

  const getField = (fieldName) => {
    const field = data?.data?.find((item) => item.field_name === fieldName);
    if (field && field.toolkit_label_status === "Inactive") {
      return '';
    }
    return field ? field.field_value : "";
  };

  return (
    <div className="share-calculator toolkit4-container">
      <div className="toolkit4-content">
        {/* Header Row */}
        <h3 className="background-blue font-white tool-header">
          <i className="fas fa-calculator"></i> {getField("toolheader_header")}
        </h3>

        {/* Combined Table */}
        <table className="tool-table">
          <thead>
            <tr>
              <th>{getField("name")}</th>
              <th>{getField("symbol")}</th>
              <th>{getField("latest")}</th>
              <th>{getField("next_report")}</th>
            </tr>
          </thead>
          <tbody>
            {/* Initial Rows */}
            <tr>
              <td className="td-tool-value">{getField("company_name")}</td>
              <td className="td-tool-value">{getField("symbol_name")}</td>
              <td className="td-tool-value">{getField("latest_value")}</td>
              <td className="td-tool-value">{getField("next_report_value")}</td>
            </tr>

            {/* Analyst Information */}
            <tr>
              <th>{getField("analyst")}</th>
              <th>{getField("shares")}</th>
              <th>{getField("market_px")}</th>
              <th>{getField("market_cap")}</th>
            </tr>
            <tr>
              <td className="exchange-rate-result">{userName}</td>
              <td className="td-tool-value">{formatNumberWithCommasAndMillions(Number(getField("shares_value")))}</td>
              <td className="td-tool-value">${getField("market_px_value")}</td>
              <td className="td-tool-value">${formatNumberWithCommasAndMillions(Number(getField("market_cap_value")))}</td>
            </tr>

            {/* Section Header */}
            <tr>
              <td colSpan="4" className="toolkit-full-row-header">
                {getField("lesson_header")}
              </td>
            </tr>

            {/* Column Headers */}
            <tr>
              <th></th>
              <th>{getField("year")}</th>
              <th>{getField("baseline")}</th>
              <th>{getField("forecast")}</th>
            </tr>

            {/* Additional Rows */}
            <tr className="background-blue font-white">
              <td>{getField("valuation_summary")}</td>
              <td >{getField("year_value")}</td>
              <td >{getField("baseline_value")}</td>
              <td >{getField("forecast_value")}</td>
            </tr>
            <tr>
              <td>{getField("quarterly")}</td>
              <td className="td-tool-value">${formatNumberWithCommasAndMillions(Number(getField("quarterly_year")))}</td>
              <td className="td-tool-value">${formatNumberWithCommasAndMillions(Number(getField("quarterly_baseline")))}</td>
              <td className="td-tool-value">${formatNumberWithCommasAndMillions(Number(getField("quarterly_forecast")))}</td>
            </tr>
            <tr>
              <td>{getField("annual")}</td>
              <td className="td-tool-value">${formatNumberWithCommasAndMillions(Number(getField("annual_year")))}</td>
              <td className="td-tool-value">${formatNumberWithCommasAndMillions(Number(getField("annual_baseline")))}</td>
              <td className="td-tool-value">${formatNumberWithCommasAndMillions(Number(getField("annual_forecast")))}</td>
            </tr>
            <tr>
              <td>{getField("cash")}</td>
              <td className="td-tool-value">${formatNumberWithCommasAndMillions(Number(getField("cash_year")))}</td>
              <td className="td-tool-value">${formatNumberWithCommasAndMillions(Number(getField("cash_baseline")))}</td>
              <td className="td-tool-value">${formatNumberWithCommasAndMillions(Number(getField("cash_forecast")))}</td>
            </tr>
            <tr>
              <td>{getField("shares_outstanding")}</td>
              <td className="td-tool-value">${formatNumberWithCommasAndMillions(Number(getField("shares_outstanding_year")))}</td>
              <td className="td-tool-value">{formatNumberWithCommasAndMillions(Number(getField("shares_outstanding_baseline")))}</td>
              <td className="td-tool-value">${formatNumberWithCommasAndMillions(Number(getField("shares_outstanding_forecast")))}</td>
            </tr>
            <tr>
              <td>{getField("cash_flow")}</td>
              <td className="td-tool-value">${formatNumberWithCommasAndMillions(Number(getField("cash_flow_year")))}</td>
              <td className="td-tool-value">${formatNumberWithCommasAndMillions(Number(getField("cash_flow_baseline")))}</td>
              <td className="td-tool-value">${formatNumberWithCommasAndMillions(Number(getField("cash_flow_forecast")))}</td>
             
            </tr>
            <tr>
              <td>{getField("market")}</td>
              <td className="td-tool-value">${formatNumberWithCommasAndMillions(Number(getField("market_year")))}</td>
              <td className="td-tool-value">${formatNumberWithCommasAndMillions(Number(getField("market_baseline")))}</td>
              <td className="td-tool-value">${formatNumberWithCommasAndMillions(Number(getField("market_forecast")))}</td>
            </tr>
            <tr>
              <td>{getField("implied")}</td>
              <td className="td-tool-value">{getField("implied_year")}%</td>
              <td className="td-tool-value">{getField("implied_baseline")}%</td>
              <td className="td-tool-value">{getField("implied_forecast")}%</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

Program2Toolkit1.propTypes = {
  data: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        field_name: PropTypes.string.isRequired,
        field_value: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
};

export default Program2Toolkit1;
