
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {  getQuizById } from "../../../../api/studentApi";
import {createUserEvent ,getPrintTemplate,getUserTrainingProgramStatus
  ,saveLatestLessonProgress,
  fetchLatestLessonProgress
} from "../../../../api/programApi"
import LessonQuiz from "./LessonQuiz";
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import { setTrainingStatus } from "../../../../components/common/redux/trainingSlice";
import { useDispatch,useSelector } from 'react-redux';
import LessonPagination from "./LessonPagination";
import {trackEvent} from "../../../../utils/trackEvent";
import { getCourses } from "../../../../api/programApi";

const LessonContent = ({onStartLesson, lesson,trainingProgram, isLastLesson, onTrainingCompleted ,onBackToProgramContent,fetchLessonStatuses,openedCourse}) => {

  const { t } = useTranslation();
  const [printTemplate, setPrintTemplate] = useState(null);
  const [quizData, setQuizData] = useState(null); 
  const [certificateIssued, setCertificateIssued] = useState(null);
  const [submitting, setSubmitting] = useState(false)
  const [slides, setSlides] = useState([]);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [lessons, setLessons] = useState([]);
  const trainingStatus = useSelector((state) => state.training.trainingStatus);

  

  const dispatch = useDispatch();
  const navigate = useNavigate();
  
      const navigateToCourse = () => {
        onBackToProgramContent()
        navigate('/course-details');
        }

      const fetchTrainingStatus = async () => {
        const userEmail = localStorage.getItem("Email");
        const trainingId = trainingProgram.srno;
      
        try {
          const response = await getUserTrainingProgramStatus(userEmail, trainingId);
          const status = response?.[0]?.status || null;
          const certificate = response?.[0]?.certificate_issued || null;

                // Dispatch status to Redux
          dispatch(setTrainingStatus(status));
          setCertificateIssued(certificate);


        } catch (error) {
          console.error("Error fetching training status:", error);
        }
      };  

      useEffect(() => {
        fetchTrainingStatus();
      }, []);
    
  
      const handleLessonCompleted = async () => {
        setSubmitting(true)
        const eventData = {
          eventType: "Lesson Completed",
          userEmail: localStorage.getItem("Email"),  
          trainingId: trainingProgram.srno,
          courseId: lesson.course_id[0],
          lessonId: lesson.srno,
        };
        
        try {
          const response = await createUserEvent(
            eventData.eventType,
            eventData.userEmail,
            eventData.trainingId,
            eventData.courseId,
            eventData.lessonId
          );
          console.log("Lesson Completed event tracked successfully", response);
        
          trackEvent('Lesson', 'Lesson Completed');
          await fetchLessonStatuses(openedCourse)

          // Check for next lesson
          const currentIndex = lessons.findIndex(l => l.srno === lesson.srno);
          const nextLesson = lessons[currentIndex + 1];

          // Handle last lesson
          if (isLastLesson) {
            if (trainingProgram?.offer_certificate === "Yes" && certificateIssued !== "Yes") {
              navigate('/thank-you', {
                state: {
                  trainingName: trainingProgram?.program_header,
                  isCertificate: trainingProgram?.offer_certificate === "Yes",
                },
              });
            }
            
            trackEvent('Training Program', 'Training Program Completed');
            await onTrainingCompleted(); 
            await fetchTrainingStatus(); 
            setSubmitting(false)
            navigate("/student/student-dashboard")
          } else if (nextLesson) {
            // Start next lesson instead of navigating back
            onStartLesson(nextLesson, nextLesson.isLastLesson, openedCourse);
            setSubmitting(false)
          }else {
            navigateToCourse();
          }


        } catch (error) {
          console.error("Error tracking Lesson Completed event", error);
        }
      };
      

  
      const fetchPrintTemplate = async () => {
        if (lesson.lesson_template?.[0]) {
          const templateData = await getPrintTemplate(lesson.lesson_template[0]);
          setPrintTemplate(templateData || "<p>No content available</p>");
        }
      };

    
      const fetchQuizData = async (quizId) => {
        const quizData = await getQuizById(quizId);
        setQuizData(quizData);
      };
  
      useEffect(() => {
        if (lesson) {
          const userEmail = localStorage.getItem("Email"); 
          const trainingId = trainingProgram.srno;
          const lessonId = lesson.srno; 
          const courseId = lesson.course_id[0]; 

          if (userEmail && trainingId && lessonId && courseId) {
            createUserEvent("Lesson Started", userEmail, trainingId, courseId, lessonId)
              .then((response) => {
                console.log("User event Lesson Started created successfully:", response);
              })
              .catch((error) => {
                console.error("Error creating user event:", error);
              });
          }
        }
      }, [lesson]); 

      useEffect(() => {
        if (lesson) {
          if (lesson.lesson_type === "Content") {
            fetchPrintTemplate(); 
          } else if (lesson.lesson_type === "Quiz" && lesson.lesson_quiz_id.length > 0) {
            fetchQuizData(lesson.lesson_quiz_id[0]); 
          }
        }
      }, [lesson]);

      useEffect(() => {
        if (lesson?.lesson_content) {
          const content = lesson.lesson_content;
          const parser = new DOMParser();
          const doc = parser.parseFromString(content, "text/html");
      
          const allNodes = Array.from(doc.body.childNodes);
          const slideGroups = [];
          let currentSlide = [];

          allNodes.forEach((node) => {
            if (node.tagName === "PRE") {
              // Push current slide to slides if not empty
              if (currentSlide.length > 0) {
                slideGroups.push(currentSlide);
                currentSlide = [];
              }
            } else {
              currentSlide.push(node.outerHTML); // Collect non-<pre> content
            }
          });
      
          // Add remaining nodes to slides
          if (currentSlide.length > 0) {
            slideGroups.push(currentSlide);
          }
      
          setSlides(slideGroups);
        }
      }, [lesson]);

      const fetchCourses = async () => {
        const coursesData = await getCourses(trainingProgram.srno);
        if (coursesData?.data?.lessons) {
          setLessons(coursesData.data.lessons);
        }
      };
    
      // Fetch Lessons
      useEffect(() => {
        fetchCourses();
      }, [trainingProgram]);

      useEffect(() => {
       
        if (
          lesson?.lesson_type === "Content" &&
          (!lesson?.lessonStatusText || lesson?.lessonStatusText === "Pending")
        ) {
          
          const userId = localStorage.getItem("UserSrno");
          const userEmail = localStorage.getItem("Email");
          const trainingProgramId = trainingProgram?.srno;
          const trainingProgramName = trainingProgram?.program_header;
          const lessonId = lesson?.srno;
          const lessonName = lesson?.lesson_header;
          const slide = currentSlideIndex + 1;
      
          const lessonProgressData = {
            user_id: Number(userId),
            user_email: userEmail,
            training_program_id: trainingProgramId,
            training_program: trainingProgramName,
            lesson_id: lessonId,
            lesson: lessonName,
            slide: slide,
          };
         
          // Save progress when conditions are met
          saveLatestLessonProgress(lessonProgressData)
            .then((res) => console.log("Lesson progress saved:", res))
            .catch((err) => console.error("Error saving lesson progress:", err));
        }
      }, [currentSlideIndex, lesson]); 

      useEffect(() => {
        if (lesson?.lesson_type === "Content" && lesson?.lessonStatusText === "Pending") {
          const userEmail = localStorage.getItem("Email");
          const trainingProgramId = trainingProgram?.srno;
          const lessonId = lesson?.srno;
      
          fetchLatestLessonProgress(userEmail,trainingProgramId,lessonId)
            .then((res) => {
              if (res[0]?.slide !== undefined) {
                setCurrentSlideIndex(res[0]?.slide - 1);
              }
            })
            .catch((err) => console.error("Error fetching lesson progress:", err));
        }
      }, [lesson]); 
      
      
      



      
  return (
    <div className="card">
      
      {lesson && lesson.lesson_type === "Content" && (
      <>
        {/* Check if slides are available */}
        {slides.length > 0 ? (
          <div className="content-container slide">

            {/* Render the current slide */}
            <div
              dangerouslySetInnerHTML={{
                __html: slides[currentSlideIndex]?.join(""),
              }}
            />
            {/* LessonPagination for navigation */}
          <LessonPagination
            currentPage={currentSlideIndex + 1}
            totalPages={slides.length}
            onPageChange={(page) => setCurrentSlideIndex(page - 1)}
          />
    
          </div>
        ) : (
          /* If no slides, render the lesson content as usual */
          lesson?.lesson_content ? (
            <div
              className="content-container"
              dangerouslySetInnerHTML={{
                __html: lesson?.lesson_content,
              }}
            />
          ) : (
            /* Fallback to printTemplate */
            printTemplate && (
              <div
                className="content-container"
                dangerouslySetInnerHTML={{
                  __html: printTemplate,
                }}
              />
            )
          )
        )}

          <div className="d-flex justify-content-center mb-3">
          {trainingStatus !== "Completed" && (currentSlideIndex === slides.length - 1) &&  (
          <button className="btn btn-primary bg-ivot-primary"  onClick={handleLessonCompleted}>
                {submitting && (
                        <><i className="fa-solid fa-spinner fa-spin-pulse"></i>&nbsp;&nbsp;</>
                      )}{t('markLessonAsCompleted')}
          </button>)}
        </div>

      </>
    )}
      
      {lesson && lesson.lesson_type === "Quiz" && quizData && (
        <LessonQuiz trainingStatus={trainingStatus} quizData={quizData} handleLessonCompleted={handleLessonCompleted} isLastLesson={isLastLesson} onTrainingCompleted={onTrainingCompleted} onBackToProgramContent={onBackToProgramContent} trainingId={trainingProgram.srno}  />
      )}

      
      {lesson && lesson.lesson_type !== "Content" && lesson.lesson_type !== "Quiz" && (
        <p>{t('unknownLessonType')}</p>
      )}
    </div>
  );
};



LessonContent.propTypes = { 
  lesson: PropTypes.shape({
    lesson_type: PropTypes.string.isRequired, 
    lesson_quiz_id: PropTypes.arrayOf(PropTypes.string), 
    course_id: PropTypes.arrayOf(PropTypes.number).isRequired, 
    srno: PropTypes.number.isRequired, 
    lesson_template: PropTypes.arrayOf(PropTypes.string).isRequired,
    lesson_content: PropTypes.string,
    lesson_header: PropTypes.string,
    lessonStatusText: PropTypes.string,
  }).isRequired,
  trainingProgram: PropTypes.shape({
    srno: PropTypes.number.isRequired, 
    offer_certificate: PropTypes.string, 
    program_header: PropTypes.string,   
    is_Certificate: PropTypes.string,  
  }).isRequired,
  isLastLesson: PropTypes.bool, 
  onTrainingCompleted: PropTypes.func.isRequired, 
  onBackToProgramContent: PropTypes.func.isRequired, 
  fetchLessonStatuses: PropTypes.func.isRequired, 
  openedCourse:PropTypes.number.isRequired,
  onStartLesson: PropTypes.func.isRequired,
};

export default LessonContent;



