import React,{useState,useEffect} from "react";
import {calculateToolkit4} from "../../../../../api/programApi";
import {formatNumberWithCommasAndMillions} from "../../../../../utils/helper";
import PropTypes from "prop-types";
import "./tool.css";

const Toolkit4 = ({data}) => {
  const [unitSoldDaily, setUnitSoldDaily] = useState("0");
  const [calculatedData, setCalculatedData] = useState(null);

  const getField = (fieldName) => {
    const field = data?.data?.find((item) => item.field_name === fieldName);
    if (field && field.toolkit_label_status === "Inactive") {
      return '';
    }
    return field ? field.field_value : "";
  };

      useEffect(() => {
      const fetchInitialData = async () => {
        try {
          const response = await calculateToolkit4(unitSoldDaily); 
          if (response.success) {
            setCalculatedData(response.data);
          }
        } catch (error) {
          console.error('Error fetching initial data', error);
        }
      };
  
      fetchInitialData();
    }, [data]);

  const handleBlur = async () => {
    try {
      const response = await calculateToolkit4(unitSoldDaily);
      if (response.success) {
        setCalculatedData(response.data);
      }
    } catch (error) {
      console.error('Error fetching data', error);
    }
  };


  return (
    <div className="share-calculator toolkit4-container">
      <div className="toolkit4-content">
        {/* Header Row */}
        <h3 className="background-blue font-white tool-header">
          <i className="fas fa-calculator"></i> {getField("lesson_header")}
        </h3>

        {/* Main Table Structure */}
        <table className="tool-table">
          <thead>
            <tr>
              <th></th> {/* Empty Space (E.S) */}
              <th>{getField("daily")}</th>
              <th>{getField("monthly")}</th>
              <th>{getField("quarterly")}</th>
              <th>{getField("yearly")}</th>
            </tr>
          </thead>
          <tbody>
            {/* Units Sold Row */}
            <tr>
              <td>{getField("units_sold")}</td>
              <td>
                <input
                  className="form-input"
                  type="number"
                  value={unitSoldDaily}
                  onChange={(e) => setUnitSoldDaily(e.target.value)}
                  onBlur={handleBlur}
                />
              </td>
              <td className="td-tool-value exchange-rate-result">{formatNumberWithCommasAndMillions(calculatedData?.units_sold_monthly)}</td>
              <td className="td-tool-value exchange-rate-result">{formatNumberWithCommasAndMillions(calculatedData?.units_sold_quarterly)}</td>
              <td className="td-tool-value exchange-rate-result">{formatNumberWithCommasAndMillions(calculatedData?.units_sold_yearly)}</td>
            </tr>

            {/* Cost of Goods Row */}
            <tr>
              <td>{getField("cost_of_goods")}</td>
              <td>{getField("per_unit")}</td>
              <td>{getField("per_month")}</td>
              <td>{getField("per_quarter")}</td>
              <td>{getField("per_year")}</td>
            </tr>

            {/* Variable Costs Row */}
            <tr>
              <td>{getField("variable_costs")}</td>
              <td className="td-tool-value">${formatNumberWithCommasAndMillions(Number(getField("var_cost_per_unit")))}</td>
              <td className="td-tool-value exchange-rate-result">${formatNumberWithCommasAndMillions(calculatedData?.var_cost_per_month)}</td>
              <td className="td-tool-value exchange-rate-result">${formatNumberWithCommasAndMillions(calculatedData?.var_cost_per_quarter)}</td>
              <td className="td-tool-value exchange-rate-result">${formatNumberWithCommasAndMillions(calculatedData?.var_cost_per_year)}</td>
            </tr>

            {/* Fixed Costs Row */}
            <tr>
              <td>{getField("fixed_costs")}</td>
              <td className="td-tool-value exchange-rate-result">${formatNumberWithCommasAndMillions(calculatedData?.fix_cost_per_unit)}</td>
              <td className="td-tool-value">${formatNumberWithCommasAndMillions(Number(getField("fix_cost_per_month")))}</td>
              <td className="td-tool-value">${formatNumberWithCommasAndMillions(Number(getField("fix_cost_per_quarter")))}</td>
              <td className="td-tool-value">${formatNumberWithCommasAndMillions(Number(getField("fix_cost_per_year")))}</td>
            </tr>

            {/* Total Costs Row */}
            <tr>
              <td>{getField("total_costs")}</td>
              <td className="td-tool-value exchange-rate-result">${formatNumberWithCommasAndMillions(calculatedData?.total_cost_per_unit)}</td>
              <td className="td-tool-value exchange-rate-result">${formatNumberWithCommasAndMillions(calculatedData?.total_cost_per_month)}</td>
              <td className="td-tool-value exchange-rate-result">${formatNumberWithCommasAndMillions(calculatedData?.total_cost_per_quarter)}</td>
              <td className="td-tool-value exchange-rate-result">${formatNumberWithCommasAndMillions(calculatedData?.total_cost_per_year)}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

Toolkit4.propTypes = {
  data: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        field_name: PropTypes.string.isRequired,
        field_value: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
};
export default Toolkit4;
