import React,{useState,useEffect} from "react";
import PropTypes from "prop-types";
import { ivotInfinity } from "../../imagepath";
import "../../pages/course/courseDetails/tools/tool.css";
import {CalculatePMOSToolkit2} from "../../../api/programApi";
import {formatWithDollarAndCommas,stripFormatting,formatNumberWithCommasAndMillions } from "../../../utils/helper";
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { useSelector } from "react-redux";

const PMOSToolkitTwo = ({data}) => {

  const [inv, setInv] = useState("0");
  const [calculatedValue, setCalculatedValue] = useState(null);
  // const [investmentCommitmentDollars, setInvestmentCommitmentDollars] = useState(null);
  // const [variableExpensesTotalExpenses, setVariableExpensesTotalExpenses] = useState(null);

  const investmentCommitmentDollars = useSelector((state) => state.finance.investmentCommitmentDollars);
  const variableExpensesTotalExpenses = useSelector((state) => state.finance.variableExpensesTotal);

  const [isExpanded, setIsExpanded] = useState(false);
  
    const toggleExpand = () => {
      setIsExpanded((prev) => !prev);
  };

  // useEffect(() => {
  //   const investmentCommitment = localStorage.getItem("investment_commitment_dollars");
  //   const variableExpenses = localStorage.getItem("variable_expenses_total_expenses");

  //   setInvestmentCommitmentDollars(investmentCommitment || "0");
  //   setVariableExpensesTotalExpenses(variableExpenses || "0");
  // }, [calculatedValue]);



  const handleInvChange = (e) => {
    setInv(e.target.value); 
  };

  const handleBlur = async () => {
    try {
      setInv((prev) => formatWithDollarAndCommas(prev));
      
      const response = await CalculatePMOSToolkit2(stripFormatting(inv),investmentCommitmentDollars,variableExpensesTotalExpenses);

      setCalculatedValue({
        total_invested_33:response?.total_invested_33,
        total_invested_53:response?.total_invested_53,
        total_invested_73:response?.total_invested_73,
        total_si_33:response?.total_si_33,
        total_si_53:response?.total_si_53,
        total_si_73:response?.total_si_73,
        total_portfolio_33:response?.total_portfolio_33,
        total_portfolio_53:response?.total_portfolio_53,
        total_portfolio_73:response?.total_portfolio_73,
        total_interest_33:response?.total_interest_33,
        total_interest_53:response?.total_interest_53,
        total_interest_73:response?.total_interest_73,
        total_ci_33:response?.total_ci_33,
        total_ci_53:response?.total_ci_53,
        total_ci_73:response?.total_ci_73,
        monthly_overhead_33:response?.monthly_overhead_33,
        monthly_overhead_53:response?.monthly_overhead_53,
        monthly_overhead_73:response?.monthly_overhead_73,
        safety_net_33:response?.safety_net_33,
        safety_net_53:response?.safety_net_53,
        safety_net_73:response?.safety_net_73,
        implied_mi_33:response?.implied_mi_33 ,
        implied_mi_53:response?.implied_mi_53,
        implied_mi_73:response?.implied_mi_73,
        financial_freedom_33:response?.financial_freedom_33,
        financial_freedom_53:response?.financial_freedom_53,
        financial_freedom_73:response?.financial_freedom_73,
        });


        
    } catch (error) {
      console.error("Error calculating PMOSToolkit2 value", error);
    }
  };

  const getField = (fieldName) => {
    const field = data?.data?.find((item) => item.field_name === fieldName);
    if (field && field.toolkit_label_status === "Inactive") {
      return { value: "", info: "" };
    }
    return field ? { value: field.field_value, info: field.info || "" } : { value: "", info: "" };
  };
  

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        // const investmentCommitment = localStorage.getItem("investment_commitment_dollars") || "0";
        // const variableExpenses = localStorage.getItem("variable_expenses_total_expenses") || "0";
  
        // setInvestmentCommitmentDollars(investmentCommitment);
        // setVariableExpensesTotalExpenses(variableExpenses);
  
        const response = await CalculatePMOSToolkit2(stripFormatting(inv), investmentCommitmentDollars, variableExpensesTotalExpenses);
  
        setCalculatedValue({
          total_invested_33: response?.total_invested_33,
          total_invested_53: response?.total_invested_53,
          total_invested_73: response?.total_invested_73,
          total_si_33: response?.total_si_33,
          total_si_53: response?.total_si_53,
          total_si_73: response?.total_si_73,
          total_portfolio_33: response?.total_portfolio_33,
          total_portfolio_53: response?.total_portfolio_53,
          total_portfolio_73: response?.total_portfolio_73,
          total_interest_33: response?.total_interest_33,
          total_interest_53: response?.total_interest_53,
          total_interest_73: response?.total_interest_73,
          total_ci_33: response?.total_ci_33,
          total_ci_53: response?.total_ci_53,
          total_ci_73: response?.total_ci_73,
          monthly_overhead_33: response?.monthly_overhead_33,
          monthly_overhead_53: response?.monthly_overhead_53,
          monthly_overhead_73: response?.monthly_overhead_73,
          safety_net_33: response?.safety_net_33,
          safety_net_53: response?.safety_net_53,
          safety_net_73: response?.safety_net_73,
          implied_mi_33: response?.implied_mi_33,
          implied_mi_53: response?.implied_mi_53,
          implied_mi_73: response?.implied_mi_73,
          financial_freedom_33: response?.financial_freedom_33,
          financial_freedom_53: response?.financial_freedom_53,
          financial_freedom_73: response?.financial_freedom_73,
        });
      } catch (error) {
        console.error("Error fetching initial PMOSToolkit2 data", error);
      }
    };
  
    fetchInitialData();
  }, [investmentCommitmentDollars, variableExpensesTotalExpenses]); // Runs only on mount
  

  
  return (
    <div className="share-calculator toolkit4-container">
      <div className="toolkit4-content">
        <table className="tool-table">
          <thead>
            <tr>
              <th className="background-color-white" colSpan="2">
                <img
                src={ivotInfinity}
                className="infinity-logo"
                  alt="Logo"
                />
              </th>
              <th className="background-color-white" colSpan="3">
                <h4>{getField("tool_header").value}</h4>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="background-blue font-white text-center">
              
              <td colSpan="5">
                <b>

                {getField("lesson_header").value}
                </b>
                </td>
            </tr>
            <tr>
              <td>{getField("name").value}</td>
              <td>{getField("forecast_age").value}</td>
              <td>{getField("return").value}</td>
              <td colSpan="2">{getField("inflation").value}</td>
            </tr>
            <tr>
              
              <td className="td-tool-value">{getField("name_value").value}</td>
              <td className="td-tool-value">{getField("age_value").value}</td>
              <td className="td-tool-value">{getField("return_value").value}%</td>
              <td colSpan="2" className="td-tool-value">{getField("inflation_value").value}%</td>
            </tr>
            <tr>
              <td></td>
              <td>{getField("starting").value}</td>
              <td >
                <span style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>

                {getField("monthly").value}
                  {getField("monthly").info && (
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>{getField("monthly").info}</Tooltip>}
                      >
                        <i className="fas fa-info-circle me-2" style={{ cursor: 'pointer' }}></i>
                      </OverlayTrigger>
                    )}
                </span>
                  </td>
              <td>{getField("inv").value}</td>
              <td colSpan="2"></td>
            </tr>
            <tr>
              <td></td>
              <td className="td-tool-value">${getField("starting_value").value}</td>
              <td className="td-tool-value">${investmentCommitmentDollars}</td>
              <td><input
                className="form-input"
                type="text"
                value={inv}
                onChange={handleInvChange}
                onBlur={handleBlur}
                />
              </td>
              <td colSpan="2"></td>
            </tr>
            <tr className="background-deep-blue font-white">
              <td>{getField("age").value}</td>
              <td >{getField("age_value").value}</td>
              <td >{getField("age_33").value}</td>
              <td >{getField("age_53").value}</td>
              <td >{getField("age_73").value}</td>
            </tr>

            <tr>
              <td>{getField("total_portfolio").value}</td>
              <td className="td-tool-value">${getField("total_portfolio_23").value}</td>
              <td className="exchange-rate-result" >{calculatedValue?.total_portfolio_33 !== undefined ? `$${formatNumberWithCommasAndMillions(calculatedValue?.total_portfolio_33)}` : ""}</td>
              <td className="exchange-rate-result" >{calculatedValue?.total_portfolio_53 !== undefined ? `$${formatNumberWithCommasAndMillions(calculatedValue?.total_portfolio_53)}` : ""}</td>
              <td className="exchange-rate-result" >{calculatedValue?.total_portfolio_73 !== undefined ? `$${formatNumberWithCommasAndMillions(calculatedValue?.total_portfolio_73)}` : ""}</td>
            </tr>


            {isExpanded  && (<>
            <tr>
              <td>{getField("total_invested").value}</td>
              <td className="td-tool-value">${getField("total_invested_23").value}</td>
              <td >{calculatedValue?.total_invested_33 !== undefined ? `$${formatNumberWithCommasAndMillions(calculatedValue?.total_invested_33)}` : ""}</td>
              <td >{calculatedValue?.total_invested_53 !== undefined ? `$${formatNumberWithCommasAndMillions(calculatedValue?.total_invested_53)}` : ""}</td>
              <td >{calculatedValue?.total_invested_73 !== undefined ? `$${formatNumberWithCommasAndMillions(calculatedValue?.total_invested_73)}` : ""}</td>
            </tr>
          
              <tr>
              <td>{getField("total_simple_interest").value}</td>
              <td></td>
              <td >{calculatedValue?.total_si_33 !== undefined ? `$${formatNumberWithCommasAndMillions(calculatedValue?.total_si_33)}` : ""}</td>
              <td >{calculatedValue?.total_si_53 !== undefined ? `$${formatNumberWithCommasAndMillions(calculatedValue?.total_si_53)}` : ""}</td>
              <td >{calculatedValue?.total_si_73 !== undefined ? `$${formatNumberWithCommasAndMillions(calculatedValue?.total_si_73)}` : ""}</td>

            </tr>
            <tr>
              <td>{getField("total_compound_interest").value}</td>
              <td></td>
              <td >{calculatedValue?.total_ci_33 !== undefined ? `$${formatNumberWithCommasAndMillions(calculatedValue?.total_ci_33)}` : ""}</td>
              <td>{calculatedValue?.total_ci_53 !== undefined ? `$${formatNumberWithCommasAndMillions(calculatedValue?.total_ci_53)}` : ""}</td>
              <td >{calculatedValue?.total_ci_73 !== undefined ? `$${formatNumberWithCommasAndMillions(calculatedValue?.total_ci_73)}` : ""}</td>
            </tr>
            <tr>
              <td>{getField("total_interest").value}</td>
              <td></td>
              <td >{calculatedValue?.total_interest_33 !== undefined ? `$${formatNumberWithCommasAndMillions(calculatedValue?.total_interest_33)}` : ""}</td>
              <td >{calculatedValue?.total_interest_53 !== undefined ? `$${formatNumberWithCommasAndMillions(calculatedValue?.total_interest_53)}` : ""}</td>
              <td >{calculatedValue?.total_interest_73 !== undefined ? `$${formatNumberWithCommasAndMillions(calculatedValue?.total_interest_73)}` : ""}</td>
            </tr>

            <tr className="background-blue font-white">
              <td></td>
              <td colSpan="5">{getField("financial_goals").value}</td>
            </tr>
            <tr className="background-deep-blue font-white">
              <td>{getField("age").value}</td>
              <td >{getField("age_value").value}</td>
              <td >{getField("age_33").value}</td>
              <td >{getField("age_53").value}</td>
              <td >{getField("age_73").value}</td>
            </tr>

            <tr>
              <td>{getField("safety_net").value}</td>
              <td className="td-tool-value">{getField("safety_net_23").value}%</td>
              <td >{calculatedValue?.safety_net_33 !== undefined ? `${calculatedValue?.safety_net_33}%` : ""}</td>
              <td >{calculatedValue?.safety_net_53 !== undefined ? `${calculatedValue?.safety_net_53}%` : ""}</td>
              <td >{calculatedValue?.safety_net_73 !== undefined ? `${calculatedValue?.safety_net_73}%` : ""}</td>
            </tr>

            <tr>
              <td>{getField("financial_freedom").value}</td>
              <td>{getField("financial_freedom_23").value}</td>
              <td className="exchange-rate-result">{calculatedValue?.financial_freedom_33 !== undefined ? `${calculatedValue?.financial_freedom_33}%` : ""}</td>
              <td className="exchange-rate-result">{calculatedValue?.financial_freedom_53 !== undefined ? `${calculatedValue?.financial_freedom_53}%` : ""}</td>
              <td className="exchange-rate-result">{calculatedValue?.financial_freedom_73 !== undefined ? `${calculatedValue?.financial_freedom_73}%` : ""}</td>
            </tr>

            <tr>
              <td>{getField("monthly_overhead").value}</td>
              <td className="td-tool-value"> ${formatNumberWithCommasAndMillions(Number(variableExpensesTotalExpenses))}</td>
              <td >{calculatedValue?.monthly_overhead_33 !== undefined ? `$${formatNumberWithCommasAndMillions(calculatedValue?.monthly_overhead_33)}` : ""}</td>
              <td >{calculatedValue?.monthly_overhead_53 !== undefined ? `$${formatNumberWithCommasAndMillions(calculatedValue?.monthly_overhead_53)}` : ""}</td>
              <td >{calculatedValue?.monthly_overhead_73 !== undefined ? `$${formatNumberWithCommasAndMillions(calculatedValue?.monthly_overhead_73)}` : ""}</td>

            </tr>
            <tr>
              <td>{getField("implied_monthly_income").value}</td>
              <td className="td-tool-value">${(getField("implied_mi_23").value)}</td>
              <td >{calculatedValue?.implied_mi_33 !== undefined ? `$${formatNumberWithCommasAndMillions(calculatedValue?.implied_mi_33)}` : ""}</td>
              <td >{calculatedValue?.implied_mi_53 !== undefined ? `$${formatNumberWithCommasAndMillions(calculatedValue?.implied_mi_53)}` : ""}</td>
              <td >{calculatedValue?.implied_mi_73 !== undefined ? `$${formatNumberWithCommasAndMillions(calculatedValue?.implied_mi_73)}` : ""}</td>
            </tr>
            

            </>)}
            
          </tbody>
        </table>
          {/* Toggle Button */}
          <div className="toggle-button" onClick={toggleExpand}>
                    <button className={isExpanded ? "up" : ""}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="32"
                            height="32"
                            viewBox="0 0 24 24"
                        >
                            <path d="M7 10l5 5 5-5H7z" />
                        </svg>
                    </button>
          </div>
                {/* Toggle Button */}
      </div>
    </div>
  );
};

PMOSToolkitTwo.propTypes = {
    data: PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape({
          field_name: PropTypes.string.isRequired,
          field_value: PropTypes.string.isRequired,
        })
      ).isRequired,
    }).isRequired,
  };

export default PMOSToolkitTwo;
