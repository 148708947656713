import React from "react";
import Footer from "../../footer";
import PageHeader from "../header";
import { useTranslation } from "react-i18next";

const Disclaimer = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="main-wrapper">
        <PageHeader />
        <div className="breadcrumb-bar">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-12">
                <div className="breadcrumb-list">
                  <nav aria-label="breadcrumb" className="page-breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        {t('legalNotice')}
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="page-banner">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 col-12">
                        <h1 className="mb-0">{t('legalNotice')}</h1>
                    </div>
                </div>
            </div>
        </div>

        <div className="page-content">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="terms-content">
                            <div className="terms-text">
                            <p>{t("disclaimer_general")}</p>
                            <p>{t("disclaimer_usage")}</p>
                            <p>{t("disclaimer_user_info")}</p>
                            {/* <p>Aviso Legal: Declaraci&oacute;n de Exenci&oacute;n de Responsabilidad de IVoT Academy</p>

<p>**GENERAL**</p>

<p>IVoT Academy (la &quot;Firma&quot;) es solo una herramienta educativa. Nada en este sitio debe considerarse como asesoramiento financiero, de inversi&oacute;n, fiscal o legal. La Firma no hace representaciones de que proporcionamos algo m&aacute;s que una plataforma educativa y no ofrecemos planificaci&oacute;n financiera ni recomendaciones de inversi&oacute;n. Su uso de este Sitio es bajo su propio riesgo y usted acepta utilizar este Sitio &uacute;nicamente con fines educativos. Sin perjuicio de cualquier otra disposici&oacute;n aqu&iacute;, la Firma se reserva el derecho de cambiar, modificar o alterar de cualquier otra manera cualquier caracter&iacute;stica de este Sitio, en cada caso, en cualquier momento y para cualquier prop&oacute;sito, sin previo aviso por escrito.</p>

<p>**USO DEL SITIO**</p>

<p>Al acceder o utilizar el Sitio, usted acepta cumplir con todas las leyes, reglas y regulaciones aplicables, as&iacute; como con estos T&eacute;rminos. Su acceso y uso del Sitio est&aacute;n condicionados a su cumplimiento con las reglas de conducta establecidas en esta secci&oacute;n. Su incumplimiento de cualquiera de estas reglas puede resultar en la terminaci&oacute;n de su acceso o uso del Sitio (o de cualquier parte del mismo). Usted acepta que no:</p>

<p>- Usar&aacute; el Sitio para ning&uacute;n prop&oacute;sito fraudulento, da&ntilde;ino o ilegal.</p>

<p>- Reproducir&aacute;, duplicar&aacute;, copiar&aacute;, modificar&aacute;, adaptar&aacute;, traducir&aacute;, vender&aacute;, revender&aacute; o explotar&aacute; de cualquier otra manera con fines comerciales cualquier parte del (o cualquier acceso a o uso de) el Sitio sin nuestro consentimiento previo por escrito.</p>

<p>- Eliminar&aacute; cualquier aviso de derechos de autor, marca registrada u otros derechos de propiedad del Sitio o de materiales que originen del Sitio.</p>

<p>- Utilizar&aacute; cualquier robot, ara&ntilde;a, aplicaci&oacute;n de b&uacute;squeda/recuperaci&oacute;n del sitio u otro dispositivo manual o autom&aacute;tico para recuperar, indexar, &ldquo;raspar,&rdquo; &ldquo;minar datos&rdquo; o de otro modo recopilar contenido del Sitio, o reproducir o eludir la estructura de navegaci&oacute;n o presentaci&oacute;n del Sitio, sin nuestro consentimiento previo por escrito.</p>

<p>**INFORMACI&Oacute;N DEL USUARIO**</p>

<p>Este Sitio puede contener caracter&iacute;sticas interactivas que permiten a los usuarios enviar, publicar, mostrar o transmitir contenido o material a nosotros a trav&eacute;s del Sitio (colectivamente, &ldquo;Informaci&oacute;n del Usuario&rdquo;). Usted es el propietario de su Informaci&oacute;n del Usuario y por la presente otorga, y acepta otorgar, a la Firma una licencia irrestricta, mundial, irrevocable, no exclusiva, perpetua, totalmente pagada, libre de regal&iacute;as y transferible para acceder, ver, mostrar, reproducir, adaptar, publicar, traducir, almacenar, alojar, almacenar en cach&eacute;, mantener, modificar, distribuir y utilizar de cualquier otra manera su Informaci&oacute;n del Usuario de manera consistente con todos los requisitos legales y de cumplimiento.</p> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
      </div>
    </>
  );
};

export default Disclaimer;
