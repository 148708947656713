import { createSlice } from '@reduxjs/toolkit';

const partnerSlice = createSlice({
  name: 'partner',
  initialState: {
    partnerLogo: [], // Initially empty
  },
  reducers: {
    setPartnerLogo: (state, action) => {
      state.partnerLogo = action.payload; // Store fetched logo
    },
  },
});

export const { setPartnerLogo } = partnerSlice.actions;
export default partnerSlice.reducer;
