import { useEffect,useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPartnerLogo } from "../common/redux/partnerSlice"; // Import action
import { GetPartnerDetails } from "../../api/programApi";

const PartnerLogoComponent = () => {
    const dispatch = useDispatch();
    const partnerLogo = useSelector((state) => state.partner.partnerLogo);
    const [loading, setLoading] = useState(true);
  
        useEffect(() => {
        const fetchLogo = async () => {
            const storedLogo = localStorage.getItem("partnerLogo");
            if (storedLogo) {
            dispatch(setPartnerLogo(storedLogo));
            setLoading(false);
            } else {
            const campaign = localStorage.getItem("campaign") || "Default";
            const partnerData = await GetPartnerDetails(campaign);

            const logo = partnerData?.[0]?.partner_details?.partner_logo || [];

            localStorage.setItem("partnerLogo", logo);
            dispatch(setPartnerLogo(logo));
            setLoading(false);
            }
        };
    
        fetchLogo();
        }, [dispatch]);
  
    if (loading) {
        return null} // Hide component while loading

    if (!partnerLogo || partnerLogo.length === 0) {
        return null} // Hide if no image
  
    return <img src={partnerLogo} alt="Partner Logo" style={{ marginLeft: '10px', height: '50px' }} />;
  };
  

export default PartnerLogoComponent;
