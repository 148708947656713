import React,{useState,useEffect,useRef} from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch  } from "react-redux";
import {CloseCircle,CheckCircle } from "../../imagepath";
import {GetAllSubscriptions,GetPaymentStatus} from "../../../api/programApi.js";
import Header from "../../../components/student/header/index.jsx";
import { useTranslation } from "react-i18next";
import StudentSidebar from "../../../components/student/sidebar/index.jsx";
import { showConfetti } from "../../../components/common/redux/confettiSlice.js";
import {setSubscriptionStatus} from "../../../components/common/redux/userSlice.jsx";
import {getStudentDetails} from "../../../api/studentApi.js";

const PricingPlan2 = () => {
  const dispatch = useDispatch();
  const [subscriptions,setSubscriptions] = useState(null);
  const navigate = useNavigate();
  const [isLoading,setIsLoading] = useState(false);
  
  const intervalRef = useRef(null);
  const { t } = useTranslation();
  
  const userEmail = localStorage.getItem("Email");
  



  const handleBuyNow = async (paymentLink) => {
    
    const timestamp = Date.now();
    setIsLoading(true); 
    try {
      const userSrno = localStorage.getItem("UserSrno");
      // Open the payment page
      window.open(`${paymentLink}?prefilled_email=${encodeURIComponent(userEmail)}&client_reference_id=${userSrno}`, "_blank");
      await checkPaymentStatus(userEmail, timestamp);
    } catch (error) {
      console.error("Error during payment process:", error);
    } 
  };



  const checkPaymentStatus = async (userEmail, timestamp) => {
    const pollPaymentStatus = async () => {
      try {
        const paymentResponse = await GetPaymentStatus(userEmail, timestamp);
        
  
        // Case 1: Payment pending (empty array)
        if (Array.isArray(paymentResponse) && paymentResponse.length === 0) {
          console.log("Payment Pending...");
          return; // Continue polling
        }
  
        // Case 2: Payment pending explicitly
        if (
          Array.isArray(paymentResponse) &&
          paymentResponse[0]?.actual_status === "Pending"
        ) {
          console.log("Payment status is Pending. Retrying...");
          return; // Continue polling
        }
  
        // Case 3: Payment success
        if (
          Array.isArray(paymentResponse) &&
          paymentResponse[0]?.actual_status === "Success"
        ) {
          clearInterval(intervalRef.current); // Stop polling
          dispatch(showConfetti()); // Trigger confetti
          setIsLoading(false);

            // After successful payment, fetch updated subscription status
          const updatedUserData = await getStudentDetails(userEmail); // Fetch the updated user data
                  // Extract subscription status or set "Inactive" if no active subscription
          const subscriptionStatus =   updatedUserData.data.data[0].subscriptions?.[0]?.status;
          const statusToUse = subscriptionStatus || "Inactive"; 

          // Dispatch the updated subscription status to Redux
          dispatch(setSubscriptionStatus(statusToUse));

          navigate("/student/student-dashboard");
          return;
        }
  
        // Case 4: Payment failed or unexpected status
        clearInterval(intervalRef.current); // Stop polling
        setIsLoading(false); // Hide loader
        console.log("Payment Failed or Unexpected Status");
        navigate("/pricing-plan2", {
          state: { message: "Payment failed. Please try again." },
        });
      } catch (error) {
        console.error("Error checking payment status:", error);
        clearInterval(intervalRef.current); // Stop polling
        setIsLoading(false); // Hide loader
      }
    };
  
    // Start polling every 7 seconds
    intervalRef.current = setInterval(pollPaymentStatus, 7000);
  
    // Stop polling after 5 minutes
    setTimeout(() => clearInterval(intervalRef.current), 300000);
  };
  
  
  



 

  useEffect(() => {
      const fetchAllSubcriptions = async () => {

        try{
          const response = await GetAllSubscriptions();
          if(response){
            setSubscriptions(response);
          }
        }
        catch(error){
          console.error("Error while fetching subscription data", error);
        }
      }
      fetchAllSubcriptions();
  },[])


  return (
    <>
      <div className="main-wrapper">
          <Header/>

        <section className="page-content">
          <div className="container">
          <div className="row">
          <StudentSidebar />
          <div className="col-xl-9 col-lg-9 container-with-margin" style={{ marginTop: "60px" }}>
            <div className="title-sec text-center">
              <h4>{t("chooseTheRightPlan")}</h4>
            </div>
            <div className="row"> {/* Wrap cards in a row */}
              {!subscriptions && <p>Loading subscriptions...</p>}
              {subscriptions?.length === 0 && <p>No subscriptions available.</p>}
              {subscriptions?.map(subscription => (
                <div className="col-lg-4 col-md-6 mb-4 d-flex" key={subscription?.product_id}>
                  <div className="price-box w-100">
                    <h5>
                      
                      <span style={{ color: "#376dcc" }}>{subscription?.subscription_name}</span>
                    </h5>
                    <p>{subscription?.description}</p>
                    <h3>
                      <span>{subscription?.currency}</span>{subscription?.price}
                    </h3>
                    {subscription?.tags && <span className="recom-badge">{subscription?.tags}</span>}
                    <h5>{t("features")}</h5>
                    <ul>
                      {subscription?.included_features?.map((feature, index) => (
                        <li key={`${feature.item_description}-${index}`}>
                          {feature?.included ? (
                            <img src={CheckCircle} alt="icon" style={{ marginRight: "10px" }} />
                          ) : (
                            <img src={CloseCircle} alt="icon" />
                          )}
                          {feature?.item_description}
                        </li>
                      ))}
                    </ul>
                    <button
                      onClick={() => handleBuyNow(subscription?.payment_link)}
                      className="btn btn-free w-100 btn-buy-now"
                    >
                      {t("buyNow")}
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
          </div>
        </section>     
         {/* Loader Overlay */}
         {isLoading && (
          <div className="position-fixed top-0 start-0 w-100 h-100 bg-dark bg-opacity-50 d-flex justify-content-center align-items-center">
            <p className="text-light text-center">Payment is being processed...</p>
            <div className="spinner-border text-light" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default PricingPlan2;
