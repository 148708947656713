import React, { useEffect,
   useState
   } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { getStudentDetails } from "./api/studentApi";
import { setSubscriptionStatus } from "../src/components/common/redux/userSlice"; 
import Header from "../src/components/student/header";  // Import Header
import StudentSidebar from "../src/components/student/sidebar"; /// Import Sidebar
import { ShimmerSimpleGallery } from "react-shimmer-effects";

const PrivateRoute = ({requireSubscription}) => {
  const dispatch = useDispatch();
  const [ loading , setLoading] = useState(true);

  const isAuthenticated = localStorage.getItem("SessionId") ? true : false;
  const subscriptionStatus = useSelector((state) => state.userSlice.subscriptionStatus);
  const userEmail = useSelector((state) => state.userSlice.email); 

 
  useEffect(() => {
    const fetchSubscriptionStatus = async () => {
      if (isAuthenticated && userEmail) {
        try {
          const updatedUserData = await getStudentDetails(userEmail);
          const subscriptionStatus = updatedUserData.data.data[0].subscriptions?.[0]?.status || "Inactive";
          dispatch(setSubscriptionStatus(subscriptionStatus));
        } catch (error) {
          console.error("Error fetching subscription status", error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchSubscriptionStatus();
  }, [dispatch, isAuthenticated, userEmail]);


  

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  if (loading) {
    return (
      <div className="main-wrapper">
        <Header />
        <div className="page-content bg-light-grayish-blue">
          <div className="container-fluid">
            <div className="row">
              <StudentSidebar />
              <div className="col-xl-9 col-lg-9 d-flex gap-3 flex-wrap container-with-margin" style={{ marginTop: "100px" }}>

                <div style={{ width: "400px", height: "450px", background: "#f6f7f8", borderRadius: "10px" }}>
                  <ShimmerSimpleGallery row={1} col={1} />
                </div>
                <div style={{ width: "400px", height: "450px", background: "#f6f7f8", borderRadius: "10px" }}>
                  <ShimmerSimpleGallery row={1} col={1} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }


  if (requireSubscription &&  subscriptionStatus !== "Active") {
    return <Navigate to="/pricing-plan2" />;
  }

  return <Outlet />;
};

PrivateRoute.propTypes = {
  requireSubscription: PropTypes.bool,
};

export default PrivateRoute;