import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ForecasterToolkit from './ForecasterToolkit';
import {getCompanyList} from "../../../api/programApi"; 
import AddToolkitCard from './AddToolkitCard';
import { toast } from 'react-toastify';



const ForecasterToolbox = ({ toolkits, toolkitData, onAddToolkit,onDeleteToolkit ,fetchToolkitData }) => {


  const [showDropdown, setShowDropdown] = useState(false);
  const [companyList, setCompanyList] = useState([]);


  useEffect(() => {
    const fetchCompanyList = async () => {
      try {
        const res = await getCompanyList();
        setCompanyList(res.map(({ Company_Name, Company_Symbol }) => ({ Company_Name, Company_Symbol })));
      } catch (err) {
        console.error("Error fetching company list", err);
      }
    };
  
    fetchCompanyList();
  }, []);

  const handleAddCompany = (Company_Symbol) => {
    if (toolkits.some(toolkit => toolkit.companySymbol === Company_Symbol)) {
      toast.error("This tool is already associated with the selected company.");
    } else {
      onAddToolkit(Company_Symbol); 
      toast.success("Tool added successfully!");
    }
    setShowDropdown(false); 
  };

  
  return (
    <div className="toolkits-container">
      {toolkits.map((toolkit) => {
        return (
        <div
          key={toolkit.id}
          className="toolkit-card"
        >
              <button
                className="toolkit-close-btn"
                onClick={() => {
                  onDeleteToolkit(toolkit.companySymbol);
                }}
              >
                   <i className="fas fa-times"></i>
              </button>
          <ForecasterToolkit 
              data={toolkitData}
              companySymbol = {toolkit.companySymbol}
              fetchToolkitData={fetchToolkitData} 
              growthQuarter={toolkit?.calculatedData?.growth_rate_quarter_27 }
              percentQuarter={toolkit?.calculatedData?.percent_sales_quarter_27 }
           />

        </div>
      )})}

      {/* AddToolkitCard */}
      <div className="add-toolkit-card">
        <AddToolkitCard onClick={() => setShowDropdown(!showDropdown)} />
        {showDropdown && (
            <div className="dropdown mt-2">
              {companyList.map(({ Company_Name, Company_Symbol }) => (
                <button
                  key={Company_Symbol}
                  className="dropdown-item"
                  onClick={() => handleAddCompany(Company_Symbol)} 
                >
                  {Company_Name}
                </button>
              ))}
            </div>
          )}
      </div>
    </div>
  );
};

ForecasterToolbox.propTypes = {
  toolkits: PropTypes.array.isRequired,
  toolkitData: PropTypes.object,
  onAddToolkit: PropTypes.func.isRequired,
  onDeleteToolkit: PropTypes.func.isRequired,
  fetchToolkitData: PropTypes.func.isRequired,
};

export default ForecasterToolbox;
