import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  investmentCommitmentDollars: localStorage.getItem("investment_commitment_dollars") || "",
  variableExpensesTotal: localStorage.getItem("variable_expenses_total_expenses") || "",
};

const financeSlice = createSlice({
  name: "finance",
  initialState,
  reducers: {
    setInvestmentCommitmentDollars: (state, action) => {
      state.investmentCommitmentDollars = action.payload;
      localStorage.setItem("investment_commitment_dollars", action.payload); // Sync with localStorage
    },
    setVariableExpensesTotal: (state, action) => {
      state.variableExpensesTotal = action.payload;
      localStorage.setItem("variable_expenses_total_expenses", action.payload); // Sync with localStorage
    },
  },
});

export const { setInvestmentCommitmentDollars, setVariableExpensesTotal } = financeSlice.actions;
export default financeSlice.reducer;
