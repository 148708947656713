
export function formatToCustomISOString(date) {
    const pad = (num, size) => {
        let s = "000" + num;
        return s.slice(-size);
    };

    const year = date.getFullYear();
    const month = pad(date.getMonth() + 1, 2);
    const day = pad(date.getDate(), 2);
    const hours = pad(date.getHours(), 2);
    const minutes = pad(date.getMinutes(), 2);
    const seconds = pad(date.getSeconds(), 2);
    const milliseconds = pad(date.getMilliseconds(), 3);
    
    // Add three additional digits for microseconds, default to '000'
    const microseconds = milliseconds + '000';

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${microseconds}`;
}



export const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

 export  const adjustTimestampFormat = (isoString) => {
    // Convert the ISO string to a Date object
    const date = new Date(isoString);
    
    // Extract the date components
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const day = String(date.getDate()).padStart(2, '0');
    
    // Extract time components
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    
    // Milliseconds and extend to microseconds (padding with zeros)
    const milliseconds = String(date.getMilliseconds()).padStart(3, '0');
    const microseconds = milliseconds + '000'; // Convert milliseconds to microseconds

    // Combine the components in the desired format
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${microseconds}`;
};

export function formatNumberWithCommas(number) {
    return number.toLocaleString();
  }

export function formatNumberWithCommasAndMillions(number) {
  if (number == null || isNaN(number)) {
    return '0'; // Return '0' for invalid or undefined input
  }

  const absNumber = Math.abs(number); // Work with the absolute value
  const isNegative = number < 0; // Check if the number is negative

  if (absNumber >= 1000000) {
    const formattedNumber = `${(absNumber / 1000000).toFixed(1)}M`; // Round to 1 decimal place and add 'M'
    return isNegative ? `-${formattedNumber}` : formattedNumber; // Add the '-' sign back if the number is negative
  }

  // Handle numbers less than 1 million
  return number.toLocaleString();
}

  
  export function truncateString(str, maxLength = 24) {
    if (str.length > maxLength) {
        return str.substring(0, maxLength - 3) + '...';
    }
    return str;
}

export const formatWithDollarAndCommas = (value) => {
  const numericValue = parseFloat(value.replace(/[^0-9.]/g, '')) || 0;
  return `$${numericValue.toLocaleString('en-US', { maximumFractionDigits: 2 })}`;
};

export const stripFormatting = (value) => value.replace(/[^0-9.]/g, '');

export const handlePercentageChange = (setValue) => (e) => {
  const value = e.target.value.replace(/[^0-9.-]/g, ''); // Keep only numbers, periods and negative signs
  const formattedValue = value.startsWith('-') ? `-${value.replace(/-/g, '')}` : value; // Ensure only one leading negative sign
  setValue(formattedValue ? `${formattedValue}%` : ''); 
};

export const stripPercentage = (value) => value.replace('%', '');