import { useNavigate } from "react-router-dom";
import {trackEvent} from "../utils/trackEvent";

const useLogout = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    const email = localStorage.getItem("Email"); 
    trackEvent("Logout", "Logout Button Clicked", email);
    
    localStorage.removeItem("Name");
    localStorage.removeItem("Email");
    localStorage.removeItem("SessionId");
    localStorage.removeItem("allTrainingPrograms");
    localStorage.removeItem("userAvatar");
    localStorage.removeItem("UserId");
    localStorage.removeItem("UserSrno");
		localStorage.removeItem("quizSubmission");
		localStorage.removeItem("trainingProgram");
		localStorage.removeItem("userLanguage");
		localStorage.removeItem("quizSubmissionData");
		localStorage.removeItem("studentDetails");
		localStorage.removeItem("investment_commitment_dollars");
		localStorage.removeItem("variable_expenses_total_expenses");
		localStorage.removeItem("partnerLogo");
		localStorage.removeItem("campaign");
    
  };

  const handleLogoutAndRedirect = (redirectTo = "/home") => {
    handleLogout();
    navigate(redirectTo);
  };

  return { handleLogoutAndRedirect };
};

export default useLogout;
