import React from "react";
import { Link } from "react-router-dom";
import { Icon19, Icon20, logo2 } from "../imagepath";
import { useTranslation } from "react-i18next";
const Footer = () => {
  const { t } = useTranslation();
  return (
    <footer className="footer">
      {/* Footer Top */}
      <div className="footer-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              {/* Footer Widget */}
              <div className="footer-widget footer-about">
                <div className="footer-logo">
                  <img src={logo2} alt="logo" />
                </div>
                <div className="footer-about-content">
                  <p>
                  {t('aboutIvotDescription')}               
                  </p>
                  {/* Social Media Icons */}
                  <ul className="social-icon">
                  <li>
                    <a 
                      href="https://www.facebook.com/profile.php?id=61566582544973" 
                      target="_blank" 
                      rel="noopener noreferrer"
                      className="social-link facebook"
                    >
                      <i className="fab fa-facebook-f" style={{ color: "#4267B2" }}></i>
                    </a>
                  </li>
                  <li>
                    <a 
                      href="https://www.instagram.com/ivot.academy/" 
                      target="_blank" 
                      rel="noopener noreferrer"
                      className="social-link instagram"
                    >
                      <i className="fab fa-instagram" style={{ color: "#C13584" }}></i>
                    </a>
                  </li>
                  <li>
                    <a 
                      href="https://www.linkedin.com/company/ivot-financial-training-inc/" 
                      target="_blank" 
                      rel="noopener noreferrer"
                      className="social-link linkedin"
                    >
                      <i className="fab fa-linkedin-in" style={{ color: "#0077B5" }}></i>
                    </a>
                  </li>
                  <li>
                    <a 
                      href="https://www.youtube.com/@ivotacademy" 
                      target="_blank" 
                      rel="noopener noreferrer"
                      className="social-link youtube"
                    >
                      <i className="fab fa-youtube" style={{ color: "#FF0000" }}></i>
                    </a>
                  </li>
                </ul>

                </div>
              </div>
              {/* /Footer Widget */}
            </div>
            <div className="col-lg-2 col-md-6">
              {/* Footer Widget */}
              {/* <div className="footer-widget footer-menu">
                <h2 className="footer-title">For Instructor</h2>
                <ul>
                  <li>
                    <Link to="/instructor/instructor-profile">Profile</Link>
                  </li>
                  <li>
                    <Link to="/login">Login</Link>
                  </li>
                  <li>
                    <Link to="/register">Register</Link>
                  </li>
                  <li>
                    <Link to="/instructor/instructor-list">Instructor</Link>
                  </li>
                  <li>
                    <Link to="/deposit-instructor-dashboard"> Dashboard</Link>
                  </li>
                </ul>
              </div> */}
              {/* /Footer Widget */}
            </div>
            <div className="col-lg-2 col-md-6">
              {/* Footer Widget */}
              <div className="footer-widget footer-menu">
                <h2 className="footer-title">{t('aboutIvotTitle')}</h2>
                <ul>
                  <li>
                    <Link to="/student/students-profile">{t('profile')}</Link>
                  </li>
                  <li>
                    <Link to="/login">{t('login')}</Link>
                  </li>
                  {/* <li>
                    <Link to="/register">{t('register')}</Link>
                  </li> */}
                  {/* <li>
                    <Link to="/student/students-list">Student</Link>
                  </li>
                  <li>
                    <Link to="/deposit-student-dashboard"> Dashboard</Link>
                  </li> */}
                </ul>
              </div>
              {/* /Footer Widget */}
            </div>
            <div className="col-lg-4 col-md-6">
              {/* Footer Widget */}
              <div className="footer-widget footer-contact">
                {/* <h2 className="footer-title">News letter</h2> */}
                {/* <div className="news-letter">
                  <form>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter your email address"
                      name="email"
                    />
                  </form>
                </div> */}
                <div className="footer-contact-info">
                  <div className="footer-address">
                    <img src={Icon20} alt="" className="img-fluid" />
                    <p>
                      {" "}
                      247 W 87th St, New York, 
                      <br /> NY 10024, US{" "}
                    </p>
                  </div>
                  <p>
                    <a href="mailto:support@ivot.us" rel="noopener noreferrer">
                    <img src={Icon19} alt="" className="img-fluid" />
                    support@ivot.us
                    </a>
                  </p>
                  { /*
                    <p className="mb-0">
                      <img src={Icon21} alt="" className="img-fluid" />
                      +19 123-456-7890
                    </p>
                    */
                  }
                </div>
              </div>
              {/* /Footer Widget */}
            </div>
          </div>
        </div>
      </div>
      {/* /Footer Top */}
      {/* Footer Bottom */}
      <div className="footer-bottom">
        <div className="container">
          {/* Copyright */}
          <div className="copyright">
            <div className="row">
              <div className="col-md-6">
                <div className="privacy-policy">
                  <ul>
                    <li>
                      {/* <Link to="/term-condition">Terms</Link> */}
                    </li>
                    <li>
                      {/* <Link to="/privacy-policy">Privacy</Link> */}
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6">
                <div className="copyright-text">
                  <p className="mb-0">IVoT Academy © 2024.{t('allRightsReserved')}</p>
                </div>
              </div>
            </div>
          </div>
          {/* /Copyright */}
        </div>
      </div>
      {/* /Footer Bottom */}
    </footer>
  );
};

export default Footer;
